<template>
  <v-container
    v-if="assessments"
    class="px-0 pt-0"
    fluid
  >
    <PageHero
      :image="heroImageUrl"
      :subtitle="heroSubtitle"
      :title="heroTitle"
    />

    <v-container
      v-if="assessments.length > 0"
      class="mxw-1000 mb-8"
    >
      <v-row>
        <v-col
          v-for="assessment in assessments"
          :key="assessment.id"
          cols="12"
          md="6"
        >
          <AssessmentCard :assessment="assessment" />
        </v-col>
      </v-row>
    </v-container>

    <v-row
      v-if="assessments.length == 0"
      class="justify-center px-2 mb-4"
    >
      <v-col cols="3">
        <v-img :src="$a.url('icons.nullstate')" />
      </v-col>
    </v-row>

    <CreateAssessmentDialog />

    <v-pagination
      v-show="pages > 1"
      v-model="page"
      :length="pages"
      :total-visible="8"
      class="mt-4"
    />

    <VerticalSpacer :min-height="75" />
  </v-container>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import AssessmentCard from '@/manager/components/AssessmentCard.vue';
import CreateAssessmentDialog from '@/manager/components/CreateAssessmentDialog.vue';
import PageHero from '@/shared/components/content/PageHero.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { useStore } from 'vuex';

// eslint-disable-next-line max-len
const DEFAULT_HERO_IMAGE_URL =
  'https://res.cloudinary.com/bridgecare/image/upload/v1628634744/media/Hands_-_Medal_1_zxk25f.svg';
// eslint-disable-next-line max-len
const DEFAULT_HERO_SUBTITLE =
  'Use the request button below to apply for a QRIS model (Enhancement, Guided Support, or Assessment). Ensure your request is based on your license type. For example, if you are a Family Provider, then only fill out applications labeled "Family Home". Please only fill out the application for the model in which you are ready to participate within 30 days of approval.';
const DEFAULT_HERO_TITLE = 'QRIS';

const store = useStore();

const assessments = ref([]);
const heroImageUrl = ref(store.state.pages.Assessments.hero_image_url || DEFAULT_HERO_IMAGE_URL);
const heroSubtitle = ref(store.state.pages.Assessments.hero_subtitle || DEFAULT_HERO_SUBTITLE);
const heroTitle = ref(store.state.pages.Assessments.hero_title || DEFAULT_HERO_TITLE);
const page = ref(1);
const pages = ref(null);

async function load() {
  const providers = await loadProviders();
  if (providers) {
    Api.manager.assessment.index({}, (resp) => {
      assessments.value = resp.data.map((assessment) => ({
        ...assessment,
        provider: providers.find((provider) => provider.id === assessment.provider_id),
      }));
    });
  }
}

async function loadProviders() {
  const { data } = await Api.manager.provider.index();
  return data;
}

onMounted(load);
</script>
