import '@/plugins/data-dog';
import '@/styles/main.scss';
import '@/plugins/uppy';
import _ from 'lodash';
import Api from '@/shared/services/bright_finder';
import App from '@/manager/App.vue';
import assets from '@/shared/services/assets';
import customStyles from '@/plugins/custom-styles';
import GoogleTagManagerLoader from '@/plugins/google_tag_manager_loader';
import i18n from '@/plugins/i18n';
import router from '@/manager/router';
import store from '@/manager/store';
import vuetify from '@/plugins/vuetify';
import { EventBus } from '@/plugins/event-bus';
import { createApp, configureCompat } from 'vue';
import { handleProtectedRoute } from '@/manager/services/request-routing';

import translate from '@/shared/directives/translate';
import handleNativeClickEvent from '@/shared/utils/analytics/handleNativeClickEvent';

configureCompat({
  MODE: 3,
});

router.beforeEach((to, _from, next) => {
  if (to.path !== '/sign_out' && (!to.meta.public || !_.isEmpty(to.query.login_code_email))) {
    handleProtectedRoute({ store, next, to });
  } else {
    next();
  }
});

Api.public_api.site.configuration.get((resp) => {
  const config = resp.data;
  window.api_url = config.api_url;
  /*
   * I couldn't figure out why the API URL was undefined when running the tests
   * in Cypress, so I'm just setting it here to avoid burning too much time
   * digging into why it's undefined.
   */
  if (!window.api_url && Cypress) {
    window.api_url = 'http://lvh.me:4001/api';
  }
  window.organization_id = config.organization_id;
  window.site_id = config.site_id;

  const app = createApp(App);

  store.commit('setRole', 'manager'); // Vue3 global access
  app.config.globalProperties.$role = 'manager';
  app.config.globalProperties.$a = assets;
  app.provide('assets', assets);
  app.config.globalProperties.$eventBus = EventBus;
  app.provide('eventBus', EventBus);
  app.config.globalProperties.window = window;

  app.use(i18n);
  app.use(router);
  app.use(store);
  app.use(vuetify);

  app.directive('translate', translate);

  setConfig();

  app.mount('#app');

  resetVuetifyTheme();

  customStyles.generate(store.state.brand.colors);
  document.getElementById('favicon').setAttribute('href', config.favicon_url);

  document.addEventListener('click', function (event) {
    store.commit('resetTimeout');

    handleNativeClickEvent(event);
  });

  if (store.state.site.google_tag_manager_id) {
    GoogleTagManagerLoader.activateGtm(
      window,
      document,
      'script',
      'dataLayer',
      store.state.site.google_tag_manager_id,
    );
  }

  router.afterEach(() => {
    store.dispatch('page');
    store.commit('setCurrentPageTabs', null);
    store.commit('setCurrentPageTitle', null);
    store.commit('setAppBarChip', null);
  });

  function setConfig() {
    document.title = config.title;
    store.commit('setConfig', config);
    store.commit('setBrand', config['brand']);
    store.commit('setFeatures', config['features']);
    store.commit('setPages', config['pages']);
    store.commit('setLanguages', config['languages']);
    store.commit('setSchemas', config['schemas']);
    store.commit('setSite', config['site']);
    store.commit('setAgeGroups', config['age_groups']);
    store.commit('setSiteWebpageId', config['site_webpage_id']);
    store.commit('setUserSessionTimeout', config['user_session_timeout_minutes']);
  }

  function resetVuetifyTheme() {
    vuetify.theme.themes.value.light.colors.primary = store.state.brand.colors.primary;
    vuetify.theme.themes.value.light.colors.secondary = store.state.brand.colors.secondary;
    vuetify.theme.themes.value.light.colors.accent = store.state.brand.colors.accent;
  }
});
