<template>
  <v-container>
    <ResourceDialog
      @save="save"
      ref="termsAgreementDialog"
      :cancellable="false"
      :max-width="800"
      :processing="processing"
      :save-button-text="termsAgreement.accept_label"
      :title="termsAgreement.title"
      center-title
    >
      <template #form>
        <v-row>
          <v-col>
            <MarkdownContent
              :content="termsAgreement.content"
              class="focus-very-visible fs-16"
              tabindex="0"
            />
          </v-col>
        </v-row>
      </template>
    </ResourceDialog>
  </v-container>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const eventBus = useEventBus();
const router = useRouter();
const store = useStore();

const processing = ref(false);
const termsAgreement = ref(store.state.config.terms_agreement);
const termsAgreementDialog = ref(null);

onMounted(() => {
  termsAgreementDialog.value.open({});
});

async function save() {
  processing.value = true;
  return Api.manager.profile.update(
    { terms_agreement_id: termsAgreement.value.id },
    (resp) => {
      const profile = {
        ...store.state.profile,
        terms_agreement_id: resp.data.terms_agreement_id,
      };
      store.commit('setProfile', profile);
      eventBus.chime('Profile saved');
      processing.value = false;
      router.replace({ name: 'Welcome' });
    },
    (err) => {
      eventBus.chime(err.response.data.errors.join('. '));
      processing.value = false;
    },
  );
}
</script>
