<template>
  <div>
    <v-card
      id="programs"
      class="mb-4"
      flat
      tile
    >
      <v-card-title class="tab-title d-flex">
        <v-btn
          v-if="$vuetify.display.smAndDown"
          @click="$emit('back')"
          :aria-label="$t('Back')"
          class="me-1 c-black"
          variant="text"
          icon
        >
          <v-icon
            icon="chevron_left"
            size="28"
          />
        </v-btn>
        <h2 class="v-card-title py-0 px-0">
          {{ $t(terms.programs) }}
        </h2>
        <v-spacer />
        <v-btn
          v-if="!$store.state.pages.Provider.features.enable_program_lock"
          @click="openDraft()"
          color="primary"
          data-cy="new-program-button"
          prepend-icon="add"
        >
          <span>
            {{ $t(`Create new ${terms.program.toLowerCase()}`) }}
          </span>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="my-4">
        <v-row class="mb-2">
          <v-col class="fs-18">
            {{
              $t(description) ||
              $t('Add classrooms, before or after school programs, summer programs etc.')
            }}
          </v-col>
        </v-row>

        <v-row v-if="programs.length > 0">
          <v-col
            v-for="(program, index) in programs"
            :key="index"
            class="mb-4"
            cols="12"
            lg="4"
            md="6"
          >
            <v-card
              border
              flat
              tile
            >
              <v-card-title>
                <v-row>
                  <v-col cols="10">
                    <span
                      v-text="$t(program.name)"
                      class="fs-22"
                      data-cy="program-name"
                    />
                  </v-col>
                  <v-col
                    class="d-flex justify-end pt-1"
                    cols="2"
                  >
                    <ActionMenu
                      @click:action:delete="destroy(program)"
                      @click:action:duplicate="duplicate(program)"
                      @click:action:edit="editProgram(program)"
                      :button-icon-size="20"
                      :items="actionItems"
                      :label="`Modify ${program.name}`"
                      button-data-cy="edit-program-button"
                      button-icon="more_vert"
                      left
                      x-small
                    />
                  </v-col>
                </v-row>
              </v-card-title>

              <v-card-text>
                <ProviderProgram
                  :dashboard="dashboard"
                  :program="program"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <ProgramEditor
      @save="save"
      ref="programEditor"
      :age-groups="ageGroups"
      :processing="processing"
    />
  </div>
</template>

<script setup>
import api from '@/shared/services/bright_finder';
import ProgramEditor from '@/shared/components/provider/ProgramEditor.vue';
import { ProgramParams } from '@/shared/services/program_params';
import ProviderProgram from '@/shared/components/provider/ProviderProgram.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';
import useTerms from '@/shared/composables/useTerms';

const props = defineProps({
  ageGroups: {
    type: Array,
    default: null,
  },
  description: {
    type: String,
    default: null,
  },
  providerId: {
    type: String,
    default: null,
  },
  regularHours: {
    type: Array,
    default: null,
  },
});

const emit = defineEmits(['back', 'progress', 'refresh']);

const eventBus = useEventBus();
const store = useStore();
const { terms } = useTerms();
const hours = ref([
  {
    day: 0,
    open: 700,
    close: 1800,
    included: true,
  },
  {
    day: 1,
    open: 700,
    close: 1800,
    included: true,
  },
  {
    day: 2,
    open: 700,
    close: 1800,
    included: true,
  },
  {
    day: 3,
    open: 700,
    close: 1800,
    included: true,
  },
  {
    day: 4,
    open: 700,
    close: 1800,
    included: true,
  },
  {
    day: 5,
    open: 700,
    close: 1800,
    included: false,
  },
  {
    day: 6,
    open: 700,
    close: 1800,
    included: false,
  },
]);
const dashboard = ref(null);
const processing = ref(false);
const programEditor = ref(null);
const programs = ref([]);

const actionItems = computed(() => {
  const items = [{ avatar: 'edit', event: 'edit', title: 'Edit' }];
  if (!store.state.pages.Provider.features.enable_program_lock) {
    items.push({ event: 'duplicate', title: 'Duplicate', avatar: 'content_copy' });
    items.push({ event: 'delete', title: 'Delete', avatar: 'delete' });
  }
  return items;
});

watch(() => props.providerId, load, { immediate: true });

function destroy(program) {
  // eslint-disable-next-line no-alert
  if (confirm(`Are you sure you want to remove this ${terms.program}?`)) {
    processing.value = true;
    api.program.destroy(props.providerId, program.id, async () => {
      processing.value = false;
      await load();
      emit('refresh');
    });
  }
}

function duplicate(program) {
  const draft = JSON.parse(JSON.stringify(program));
  draft.id = null;
  if (!draft.hours) {
    draft.regular_hours = true;
    draft.hours = getDefaultHours();
  }
  programEditor.value.open(draft);
}

function editProgram(program) {
  const draft = JSON.parse(JSON.stringify(program));
  if (!draft.hours) {
    draft.regular_hours = true;
    draft.hours = getDefaultHours();
  }
  programEditor.value.open(draft);
}

function getBlankProgram() {
  return {
    age_group_ids: [],
    age_max_months: true,
    age_min_months: true,
    custom: {},
    hours: getDefaultHours(),
    is_free: false,
    is_donation_based: false,
    regular_hours: true,
    season: 'Year round',
    schema_id: Object.values(store.state.schemas).filter(
      (schema) => schema.data_type === 'Program',
    )[0].id,
  };
}

function getDefaultHours() {
  if (props.regularHours) {
    return hours.value.map((newDay) => {
      const oldDay = props.regularHours.find((rh) => rh.day === newDay.day);
      const updatedNewDay = newDay;

      if (oldDay) {
        updatedNewDay.close = oldDay.close;
        updatedNewDay.included = true;
        updatedNewDay.open = oldDay.open;
        return updatedNewDay;
      }
      updatedNewDay.included = false;
      return updatedNewDay;
    }, this);
  }
  hours.value;
}

async function load() {
  processing.value = true;
  dashboard.value = null;
  programs.value = [];

  const { data } = await api.program.index(props.providerId);
  programs.value = data.map((program) => new ProgramParams(program).as_draft());
  processing.value = false;
  emit('progress', programs.value.length > 0 ? 100 : 0);
}

function openDraft() {
  const draft = getBlankProgram();
  programEditor.value.open(draft);
}

function save(draft) {
  processing.value = true;
  const params = new ProgramParams(draft).as_json();
  api.program.updateOrCreate(
    props.providerId,
    params,
    async () => {
      await load();
      processing.value = false;
      programEditor.value.close();
      eventBus.chime('Saved');
      emit('refresh');
    },
    (error) => {
      processing.value = false;
      eventBus.longChime(error.response.data.errors[0]);
    },
  );
}
</script>

<style>
.h-56 {
  height: 56px;
}

.w-150 {
  width: 150px;
}
</style>
