<template>
  <v-card
    id="enrollment_steps_card"
    :border="$role == 'specialist'"
    flat
    tile
  >
    <v-card-title class="tab-title d-flex">
      <v-btn
        v-if="$vuetify.display.smAndDown"
        @click="$emit('back')"
        class="me-1 c-black"
        variant="text"
        icon
      >
        <v-icon
          icon="chevron_left"
          size="28"
        />
      </v-btn>
      <h2 class="v-card-title py-0 px-0">
        {{ $t('How to enroll') }}
      </h2>
    </v-card-title>
    <v-divider />
    <v-card-text class="py-4">
      <p class="c-light-black fs-16 fw-500">
        {{ $t(explanationText) }}
      </p>

      <div
        v-for="(step, stepIndex) in steps"
        :key="step.id"
      >
        <v-row class="mb-2">
          <LabeledTextfield
            v-model="step.title"
            :message="[$t('Step'), stepIndex + 1].join(' ')"
            data-cy="step"
          />
          <LabeledControl message="Description">
            <ToastEditor
              :ref="'enrollStepRef' + stepIndex"
              :initial-edit-type="'wysiwyg'"
              :initial-value="step.description"
              data-cy="step_description"
            />
          </LabeledControl>
        </v-row>

        <AttachmentUploader
          @uploaded="load()"
          ref="attachment_uploader"
          :owner="{ type: 'EnrollmentStep', id: step.id }"
          class="mb-4"
        />
        <AttachmentList
          v-if="(step.meta?.attachments?.length || 0) > 0"
          @delete="load"
          :attachments="step.meta.attachments"
          class="mb-4"
        />

        <div class="d-flex justify-space-between align-center mt-6">
          <v-btn
            @click="destroy(step)"
            color="red"
            variant="outlined"
          >
            <v-icon class="me-2"> close </v-icon>
            {{ $t('Delete step') }}
          </v-btn>
        </div>

        <v-divider class="my-8" />
      </div>

      <v-btn
        v-if="!draftStepIsVisible"
        @click="addDraftStep"
        color="primary"
        size="x-large"
      >
        <v-icon
          class="me-2"
          color="white"
        >
          add
        </v-icon>
        {{ $t('Add step') }}
      </v-btn>

      <div v-show="draftStepIsVisible">
        <v-row class="mb-3">
          <LabeledTextfield
            v-model="newStep.title"
            message="New step"
          />
          <LabeledControl message="Description">
            <ToastEditor
              ref="newEnrollmentStepRef"
              :initial-edit-type="'wysiwyg'"
              :initial-value="newStep.description"
            />
          </LabeledControl>
        </v-row>
        <div class="ta-right">
          <v-btn
            @click="add"
            :disabled="!newStep.title"
            color="primary"
            size="x-large"
          >
            {{ $t('Create step') }}
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Api from '@/manager/services/bright_finder';
import ToastEditor from '@/admin/components/ToastEditor.vue';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';

const explanationText =
  'What are the steps that families need to take to enroll in your program? ' +
  'Add all relevant steps so they know what to expect. ' +
  'If you already have an electronic application or documents to complete, be sure to include them.';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LabeledControl,
    ToastEditor,
  },

  props: {
    providerId: {
      type: String,
      default: null,
    },
    requestProceedFromEnrollmentSteps: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['back', 'error', 'moveForward', 'progress'],

  data() {
    return {
      explanationText,
      index: 0,
      draftStepIsVisible: false,
      steps: [],
      newStep: {
        index: 0,
        title: null,
        description: null,
      },
    };
  },

  watch: {
    requestProceedFromEnrollmentSteps(newVal) {
      if (newVal === true) {
        this.updateAllRecords();
      }
    },
  },

  created() {
    this.load();
  },

  methods: {
    async updateAllRecords() {
      await this.recordsMap()
        .then(() => {
          // None of the records contain an error, call an event emitter to move forward
          this.$emit('moveForward');
        })
        .catch(() => {
          // Call an error emitter to reset the requestProceedFromEnrollmentSteps watched value
          this.$emit('error');
        });
    },

    async recordsMap() {
      // We need to return a new promise in order to act on the error object and resolve/reject accordingly
      return new Promise((resolve, reject) => {
        const errors = [];

        this.steps.map(async (step, index) => {
          const ref = this.$refs[`enrollStepRef${index}`];
          const markdown = this.getMarkdownForRef(ref);
          const response = await this.save(step, { markdown });

          if (response?.error === true) {
            errors.push(response);
          }

          if (errors.length > 0) {
            // There's at least 1 error in the set of records, call reject to hit the catch
            reject();
          } else {
            resolve();
          }
        });
      });
    },

    add() {
      this.getDraftMarkdown();
      Api.manager.provider.enrollment_step.create(
        this.providerId,
        this.newStep,
        () => {
          this.newStep = { title: null, description: null };
          this.load();
        },
        (err) => {
          this.$eventBus.$emit('error', err.response.data.errors[0]);
        },
      );
    },

    destroy(step) {
      Api.manager.provider.enrollment_step.destroy(this.providerId, step.id, () => this.load());
    },

    addDraftStep() {
      this.draftStepIsVisible = true;
      // Reset the draft editor as setting default values is not sufficient to clear
      // pre-existing text from previous added step
      this.$refs.newEnrollmentStepRef.reset();
    },

    load() {
      Api.manager.provider.enrollment_step.index(this.providerId, (resp) => {
        const steps = resp.data;

        this.steps = steps;
        this.calculateProgress(steps);

        if (steps.length === 0) {
          this.addDraftStep();
        } else {
          this.draftStepIsVisible = false;
        }
      });
    },

    calculateProgress(steps) {
      const progress = steps.filter((step) => step.title).length > 0 ? 100 : 0;
      this.$emit('progress', progress);
    },

    async save(step, { markdown = null } = {}) {
      const updatedStep = { ...step, description: markdown };

      const response = await Api.manager.provider.enrollment_step.update(
        this.providerId,
        step.id,
        updatedStep,
      );

      if (response === undefined) {
        // The response has an error that's already been caught by handleError in provider
        return { error: true };
      }

      return response;
    },

    getDraftMarkdown() {
      this.newStep.description = this.$refs.newEnrollmentStepRef.getMarkdown();
    },

    getMarkdownForRef(ref) {
      // need [0] as VueComponent is wrapped in Array of size 1 here
      return ref[0].getMarkdown();
    },
  },
};
</script>
