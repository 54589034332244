<template>
  <v-container
    v-if="grantProgram"
    class="mxw-1200"
  >
    <v-card
      border
      flat
      tile
    >
      <v-card-title>
        <v-row
          class="d-flex align-center"
          dense
        >
          <v-col
            cols="12"
            data-cy="grant-program-name"
            sm="9"
          >
            {{ $t(grantProgram.name) }}
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn
              @click="$refs.newGrantDialog.open()"
              color="primary"
              data-cy="apply-button"
            >
              {{ $t('Start new application') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider class="mb-4" />

      <v-card-text>
        <v-row>
          <v-col class="fs-20 fw-600">
            {{ $t('Description of grant') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="fs-16"
            data-cy="grant-program-description"
          >
            {{ $t(grantProgram.description) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="fs-16"
            cols="12"
          >
            <div
              class="d-flex align-center mb-2"
              data-cy="grant-opens"
            >
              <v-icon
                class="me-3"
                color="primary"
                icon="brightness_1"
              />
              <div class="d-inline-block">
                {{ $t('Open') }}
              </div>
              <div class="d-inline-block">
                <span class="me-1">:</span>
                <LongDate :date="grantProgram.opens_at" />
              </div>
            </div>

            <div
              class="d-flex align-center mb-4"
              data-cy="grant-closes"
            >
              <v-icon
                class="me-3"
                color="primary"
                icon="radio_button_unchecked"
              />
              <div class="d-inline-block">
                {{ $t('Closed') }}
              </div>
              <div class="d-inline-block">
                <span class="me-1">:</span>
                <LongDate
                  :date="grantProgram.closes_at"
                  nulltext="TBD"
                />
              </div>
            </div>
          </v-col>
        </v-row>

        <v-tabs
          v-model="tab"
          grow
        >
          <v-tab value="application">
            {{ $t('Applications') }}
          </v-tab>
          <v-tab
            v-if="showGrantClaims"
            value="claims"
          >
            {{ $t('Claims') }}
          </v-tab>
        </v-tabs>

        <v-window v-model="tab">
          <v-window-item value="application">
            <GrantsTable
              @duplicate="duplicateGrant"
              @share="shareGrant"
              :grants="grants"
            />
          </v-window-item>

          <v-window-item
            v-if="showGrantClaims"
            value="claims"
          >
            <GrantClaimsTable
              :grant-program="grantProgram"
              :grants="grants"
            />
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>

    <ResourceDialog
      @change="setPermissionFields"
      ref="permissionDialog"
      :fields="permissionFields"
      :processing="processing"
      save-button-text="Done"
      title="Share access"
      close-on-save
    >
      <template
        v-if="selectedGrant"
        #append="{ localValue }"
      >
        <v-btn
          @click="invite(localValue)"
          :disabled="!localValue.email && !localValue.member_id"
          class="mt-4"
          color="primary"
          data-cy="grant-invite"
        >
          <v-icon
            class="me-2"
            icon="group_add"
          />
          {{ $t('Invite') }}
        </v-btn>

        <v-divider class="my-6" />

        <v-card
          v-for="(permission, index) in permissions.filter(
            (permissionObject) => permissionObject.owner_id == selectedGrant.id,
          )"
          :key="permission.id"
          class="mt-4"
          border
          flat
          tile
        >
          <v-card-text>
            <v-row>
              <v-col class="d-flex align-center fs-14">
                <v-avatar
                  :key="permission.id"
                  :color="COLOR_OPTIONS[index]"
                  class="c-white fs-14 me-2"
                  data-cy="grant-permission"
                  size="28"
                >
                  {{ permission.member_name.split(' ')[0][0] }}
                  {{ permission.member_name.split(' ')[1][0] }}
                </v-avatar>
                <span v-text="permission.member_name" />
                <v-spacer />
                <v-btn
                  @click="destroyPermission(permission)"
                  size="x-small"
                  variant="text"
                  icon
                >
                  <v-icon icon="close" />
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @save="createGrant"
      ref="newGrantDialog"
      :max-width="700"
      :processing="processing"
      title="New application"
    >
      <template #append="{ localValue }">
        <LabeledTextfield
          v-model="localValue.name"
          message="Name your application"
        />
        <LabeledAutocomplete
          v-model="localValue.provider_id"
          @change="loadProviderPrograms"
          :items="providers"
          item-title="name"
          item-value="id"
          message="Existing site (if applicable)"
        />

        <LabeledAutocomplete
          v-model="localValue.program_id"
          :items="programs"
          item-title="name"
          item-value="id"
          message="Existing classroom (if applicable)"
        />
      </template>
    </ResourceDialog>
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import colors from 'vuetify/util/colors';
import GrantClaimsTable from '@/manager/components/grants/GrantClaimsTable.vue';
import GrantsTable from '@/manager/components/grants/GrantsTable.vue';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';

const COLOR_OPTIONS = Object.values(colors).map((color) => color.lighten2);
const TABS = {
  APPLICATION: 'application',
  CLAIMS: 'claims',
};

const store = useStore();
const router = useRouter();
const route = useRoute();

const grantProgram = ref(null);
const grants = ref([]);
const members = ref([]);
const permissionFields = ref([]);
const permissions = ref([]);
const processing = ref(false);
const programs = ref([]);
const providers = ref([]);
const selectedGrant = ref(null);
const tab = ref(null);

const permissionDialog = ref(null);
const newGrantDialog = ref(null);

watch(
  tab,
  (newValue) => {
    router.push({ query: { tab: newValue } });
  },
  { immediate: true },
);

onMounted(async () => {
  await load();
  tab.value = route.query?.tab || TABS.APPLICATION;
});

const showGrantClaims = computed(() => {
  return !processing.value && grantProgram.value.claim_schema_id && grants.value.length > 0;
});

async function load() {
  await loadGrantProgram();
  await loadGrants();
  loadPermissions();
  await loadProviders();
  await loadMembers();
}

async function loadProviderPrograms(providerId) {
  if (providerId) {
    const { data } = await Api.program.index(providerId);
    programs.value = data;
  }
}

async function loadGrantProgram() {
  const { data } = await Api.public_api.organization.grant_program.get(route.params.grantProgramId);
  grantProgram.value = data;
}

async function loadGrants() {
  const params = { grant_program_id: route.params.grantProgramId };
  const { data } = await Api.manager.grant.index(params);

  grants.value = data;
}

async function loadMembers() {
  const { data } = await Api.manager.member.index({});

  members.value = data;
  setPermissionFields();
}

function loadPermissions() {
  Api.member.permission.index({ owner_id: grants.value.map((grant) => grant.id) }, (response) => {
    permissions.value = response.data;
  });
}

function destroyPermission(permission) {
  Api.member.permission.destroy(permission.id, () => load());
}

async function loadProviders() {
  const { data } = await Api.manager.provider.index();
  providers.value = data;
}

function createGrant(newVal) {
  const newGrant = {
    ...newVal,
    grant_program_id: route.params.grantProgramId,
  };

  Api.manager.grant.create(newGrant, (response) => {
    router.push({ name: 'GrantNew', params: { grantId: response.data.id } });
  });
}

function duplicateGrant(grant) {
  const copyOfGrant = { ...grant };
  copyOfGrant.name = `Copy of ${grant.name}`;
  copyOfGrant.provider_id = null;
  copyOfGrant.program_id = null;
  newGrantDialog.value.open(copyOfGrant);
}

function shareGrant(grant) {
  selectedGrant.value = grant;
  permissionDialog.value.open();
}

async function invite(newVal) {
  processing.value = true;
  let memberResp;

  if (newVal.member_id === 'new') {
    const newMember = {
      first_name: newVal.first_name,
      last_name: newVal.last_name,
      email: newVal.email,
    };
    memberResp = await Api.manager.member.create(newMember);

    if (!memberResp) {
      processing.value = false;
      return false;
    }
  }

  const newPermission = {
    owner_id: selectedGrant.value.id,
    owner_type: 'Grant',
    member_id: memberResp ? memberResp.data.id : newVal.member_id,
    role: 'Editor',
  };

  Api.member.permission.create(newPermission, async (response) => {
    permissions.value.push(response.data);
    permissionDialog.value.clear();
    if (memberResp) await loadMembers();
    processing.value = false;
  });

  return true;
}

function setPermissionFields(change = null) {
  const memberOptions = [{ id: 'new', name: 'Invite new team member' }].concat(
    members.value.filter((member) => member.id !== store.state.profile.id),
  );

  if (!change || (change[0] === 'member_id' && change[1] !== 'new')) {
    permissionFields.value = [
      {
        cols: '12',
        text: 'Team member',
        value: 'member_id',
        items: memberOptions,
        itemText: 'name',
        itemValue: 'id',
        emptyText: 'Invite new team member',
        emptyValue: 'newUser',
      },
    ];
  } else {
    permissionFields.value = [
      {
        cols: '12',
        text: 'Team member',
        value: 'member_id',
        items: memberOptions,
        itemText: 'name',
        itemValue: 'id',
        emptyText: 'Invite new team member',
        emptyValue: 'newUser',
      },
      {
        cols: '6',
        text: 'First name',
        value: 'first_name',
      },
      {
        cols: '6',
        text: 'Last name',
        value: 'last_name',
      },
      {
        text: 'Email',
        value: 'email',
      },
    ];
  }
}
</script>
