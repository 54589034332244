<template>
  <v-card
    class="pa-0 mt-4"
    flat
    tile
  >
    <v-card-text class="pa-0">
      <v-data-table
        v-if="grants.length > 0"
        @click:row="goToGrant"
        :headers="tableHeaders"
        :items="grants"
        class="b-radius-0 b-1 bc-outlined-gray"
        item-key="id"
        dense
        fixed-header
      >
        <template #item.provider_id="{ item }">
          {{ item.provider_name }}
        </template>

        <template #item.program_id="{ item }">
          {{ item.program_name }}
        </template>

        <template #item.submitted_at="{ item }">
          <LongDateTime :date="item.submitted_at" />
        </template>

        <template #item.status="{ item }">
          {{ $t(item.status) }}
        </template>

        <template #item.actions="{ item }">
          <div class="d-flex justify-end align-center">
            <ActionMenu
              @click:action:duplicate="$emit('duplicate', item)"
              @click:action:share="$emit('share', item)"
              :items="actionItems"
              button-icon="more_vert"
            />
          </div>
        </template>

        <template #bottom />
      </v-data-table>

      <NullState v-else />
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import NullState from '@/shared/components/NullState.vue';
import { useRouter } from 'vue-router';
import useTerms from '@/shared/composables/useTerms';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { terms } = useTerms();
const { t } = useI18n();

defineProps({
  grants: {
    type: Array,
    default: () => [],
  },
});

defineEmits(['duplicate', 'share']);

const actionItems = [
  {
    title: 'Duplicate',
    avatar: 'content_copy',
    event: 'duplicate',
  },
  {
    title: 'Share',
    avatar: 'share',
    event: 'share',
  },
];

const tableHeaders = computed(() => {
  return [
    {
      title: t('Name'),
      key: 'name',
    },
    {
      title: t('Location'),
      key: 'provider_id',
    },
    {
      title: terms.value.program,
      key: 'program_id',
    },
    {
      title: t('Submitted'),
      key: 'submitted_at',
    },
    {
      title: t('Status'),
      key: 'status',
      align: 'center',
    },
    {
      title: '',
      value: 'actions',
      align: 'right',
    },
  ];
});

function goToGrant(_event, row) {
  router.push({ name: 'GrantNew', params: { grantId: row.item.id } });
}
</script>
