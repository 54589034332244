<template>
  <div
    v-if="provider && meta"
    class="w-100pc h-100pc bg-super-light-blue"
  >
    <v-row
      class="w-100pc h-100pc bg-white oy-scroll"
      no-gutters
    >
      <v-col
        v-show="$vuetify.display.mdAndUp || tab === false"
        class="br-1 bc-extra-light-gray bg-super-light-blue"
        cols="12"
        lg="2"
        md="3"
      >
        <div
          class="pa-4 bb-1 bc-very-light-gray"
          data-cy="header"
          no-gutters
        >
          <div class="bg-white rounded elevation-1 pa-3">
            <div
              v-text="provider.name"
              class="fs-16 fw-500"
            />

            <div>
              <span class="fs-14 c-black">
                {{ providerAddress() }}
              </span>
            </div>
          </div>

          <div class="d-flex align-center mt-4">
            <div class="fs-12 fw-600">
              {{ $t('Complete your profile') }}
            </div>

            <v-spacer />

            <div class="fs-12 fw-600">{{ totalProgress }}%</div>
          </div>

          <v-progress-linear
            v-model="totalProgress"
            color="primary"
          />

          <div class="d-flex justify-center mt-4">
            <v-btn
              v-if="provider.published"
              :ripple="false"
              :to="{
                name: 'PublicProviderShow',
                params: {
                  providerId: provider.id,
                },
              }"
              class="bg-white"
              color="primary"
              data-cy="view_public_profile"
              target="_blank"
              variant="outlined"
            >
              {{ $t('View public profile') }}
            </v-btn>
          </div>
        </div>

        <div
          class="listbox"
          role="listbox"
        >
          <TabHeader
            v-if="meta.overview.enabled"
            @click="transition('overview')"
            :active="tab === 'overview'"
            data-cy="overview_tab"
            title="Overview"
            hide-icon
          />
          <TabHeader
            v-if="meta.openings.enabled"
            @click="transition('openings')"
            :active="tab === 'openings'"
            :progress="progress.openings"
            data-cy="openings_tab"
            title="Openings"
          />
          <TabHeader
            v-if="meta.photos.enabled"
            @click="transition('photos')"
            :active="tab == 'photos'"
            :progress="progress.photos"
            data-cy="photos_tab"
            title="Photos"
          />
          <TabHeader
            v-if="meta.video.enabled"
            @click="transition('video')"
            :active="tab == 'video'"
            :progress="progress.video"
            data-cy="video_tour_tab"
            title="Video tour"
          />
          <TabHeader
            v-if="meta.profile.enable_description"
            @click="transition('description')"
            :active="tab == 'description'"
            :progress="progress.description"
            data-cy="description_tab"
            title="Description"
          />
          <TabHeader
            @click="transition('contact')"
            :active="tab == 'contact'"
            :progress="progress.contact"
            data-cy="contact_tab"
            title="Contact"
          />
          <TabHeader
            v-if="meta.programs.enabled"
            @click="transition('programs')"
            :active="tab == 'programs'"
            :description="meta.programs.description"
            :progress="progress.programs"
            :title="terms.programs"
            data-cy="programs_tab"
          />
          <TabHeader
            v-if="meta.tours.enabled"
            @click="transition('tours')"
            :active="tab == 'tours'"
            :progress="progress.tours"
            data-cy="tour_requests_tab"
            title="Tour requests"
          />
          <TabHeader
            v-if="meta.enrollment_steps.enabled"
            @click="transition('enrollment_steps')"
            :active="tab == 'enrollment_steps'"
            :progress="progress.enrollment_steps"
            :title="`How to ${terms.enroll.toLowerCase()}`"
            data-cy="how_to_enroll_tab"
          />
          <TabHeader
            v-if="meta.profile.enable_emergency_response"
            @click="transition('emergency')"
            :active="tab == 'emergency'"
            :progress="progress.emergency"
            data-cy="emergency_tab"
            title="COVID-19"
          />
          <TabHeader
            @click="transition('hours')"
            :active="tab == 'hours'"
            :progress="progress.hours"
            data-cy="hours_tab"
            title="Hours"
          />
          <TabHeader
            v-if="meta.profile.enable_payment_details"
            @click="transition('financial')"
            :active="tab == 'financial'"
            :progress="progress.financial"
            data-cy="financial_programs_tab"
            title="Financial programs"
          />
          <TabHeader
            v-if="meta.enrollment.enabled"
            @click="transition('enrollment')"
            :active="tab == 'enrollment'"
            :progress="progress.enrollment"
            data-cy="enrollment_tab"
            title="Enrollment"
          />
          <TabHeader
            v-if="meta.profile.enable_highlights"
            @click="transition('highlights')"
            :active="tab == 'highlights'"
            :progress="progress.highlights"
            data-cy="highlights_tab"
            title="Highlights"
          />
          <TabHeader
            v-if="meta.staff.enabled"
            @click="transition('staff')"
            :active="tab == 'staff'"
            :progress="progress.staff"
            data-cy="staff_tab"
            title="Staff"
          />
          <TabHeader
            v-if="meta.licensing.enabled"
            @click="transition('licensing')"
            :active="tab == 'licensing'"
            data-cy="licensing_tab"
            title="Licensing"
            locked
          />
        </div>
      </v-col>

      <v-col
        v-show="$vuetify.display.mdAndUp || tab != false"
        class="p-relative pa-0"
        cols="12"
        lg="10"
        md="9"
      >
        <div class="h-100pc w-100pc oy-scroll">
          <v-container fluid>
            <div
              ref="tabContainer"
              class="focus-very-visible"
              tabindex="0"
            >
              <transition-group name="slide-left">
                <div
                  v-if="meta.overview.enabled"
                  v-show="tab == 'overview'"
                  key="overview-tab"
                >
                  <ProviderOverview
                    @back="tab = false"
                    @change:publish="changePublished"
                    ref="openings"
                    :processing="processing"
                    :provider="provider"
                  />
                  <TabButtons
                    @next="next()"
                    hide-back
                  />
                </div>

                <div
                  v-if="meta.openings.enabled"
                  v-show="tab == 'openings'"
                  key="openings-tab"
                >
                  <ProgramOpenings
                    @back="tab = false"
                    @change:openings-confirmed="confirmAvailability($event)"
                    @progress="progress.openings = $event"
                    ref="openings"
                    :openings-confirmed-at="provider.openings_confirmed_at"
                    :provider-id="provider.id"
                  />
                  <TabButtons
                    @next="next()"
                    hide-back
                  />
                </div>

                <div
                  v-if="meta.photos.enabled"
                  v-show="tab == 'photos'"
                  key="photos-tab"
                >
                  <AssetManager
                    @back="tab = false"
                    @load="$refs.images.load(true)"
                    :description="meta.photos.description"
                    :provider-id="provider.id"
                    category="image"
                    class="mb-4"
                  />
                  <ProviderImages
                    @destroy="destroyAsset"
                    @progress="progress.photos = $event"
                    ref="images"
                    :provider-id="provider.id"
                    class="ma-4"
                    role="manager"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-if="meta.video.enabled"
                  v-show="tab == 'video'"
                  key="video-tab"
                >
                  <AssetManager
                    @back="tab = false"
                    @load="$refs.video.load()"
                    :description="VIRTUAL_TOUR_DESCRIPTION"
                    :provider-id="provider.id"
                    category="video"
                    class="mb-4"
                    title="Virtual tour video"
                  />
                  <VirtualTourGallery
                    @progress="progress.video = $event"
                    ref="video"
                    :provider-id="provider.id"
                    removable
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-show="tab == 'description'"
                  key="description-tab"
                >
                  <ProviderDescription
                    @back="tab = false"
                    @progress="progress.description = $event"
                    ref="description"
                    :provider="provider"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-show="tab == 'contact'"
                  key="contact-tab"
                >
                  <ContactInformation
                    @back="tab = false"
                    @progress="progress.contact = $event"
                    ref="contact"
                    :provider="provider"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-if="meta.tours.enabled"
                  v-show="tab == 'tours'"
                  key="tours-tab"
                >
                  <ProviderTours
                    @back="tab = false"
                    @progress="progress.tours = $event"
                    @refresh="load"
                    ref="tours"
                    :provider="provider"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-if="meta.enrollment_steps.enabled"
                  v-show="tab == 'enrollment_steps'"
                  key="steps-tab"
                >
                  <EnrollmentSteps
                    @back="tab = false"
                    @error="resetRequestProceedFromEnrollmentSteps"
                    @move-forward="onMoveFromEnrollmentSteps"
                    @progress="progress.enrollment_steps = $event"
                    @refresh="load"
                    ref="enrollment_steps"
                    :provider-id="provider.id"
                    :request-proceed-from-enrollment-steps="requestProceedFromEnrollmentSteps"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-show="tab == 'emergency'"
                  key="emergency-tab"
                >
                  <EmergencyResponse
                    @back="tab = false"
                    @progress="progress.emergency = $event"
                    @refresh="load()"
                    ref="emergency"
                    :provider="provider"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-show="tab == 'hours'"
                  key="hours-tab"
                >
                  <ProviderHours
                    v-model="provider"
                    @back="tab = false"
                    @progress="progress.hours = $event"
                    ref="hours"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-show="tab == 'financial'"
                  key="financial-tab"
                >
                  <PaymentDetails
                    @back="tab = false"
                    @progress="progress.financial = $event"
                    ref="financial"
                    :provider="provider"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-if="meta.programs.enabled"
                  v-show="tab == 'programs'"
                  key="programs-tab"
                >
                  <ProgramList
                    @back="tab = false"
                    @progress="progress.programs = $event"
                    @refresh="load"
                    ref="programs"
                    :age-groups="
                      $store.state.ageGroups.filter((ag) => provider.age_group_ids.includes(ag.id))
                    "
                    :description="meta.programs.description"
                    :provider-id="provider.id"
                    :regular-hours="provider.hours"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-if="meta.enrollment.enabled"
                  v-show="tab == 'enrollment'"
                  key="enrollment-tab"
                >
                  <EnrollmentCount
                    @back="tab = false"
                    @progress="progress.enrollment = $event"
                    @refresh="load"
                    ref="enrollment"
                    :description="meta.enrollment.description"
                    :provider-id="provider.id"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-show="tab == 'highlights'"
                  key="highlights-tab"
                >
                  <ProviderHighlights
                    @back="tab = false"
                    @progress="progress.highlights = $event"
                    ref="highlights"
                    :provider="provider"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-if="meta.staff.enabled"
                  v-show="tab == 'staff'"
                  key="staff-tab"
                >
                  <StaffMembers
                    @back="tab = false"
                    @progress="progress.staff = $event"
                    @refresh="load"
                    ref="staff"
                    :provider-id="provider.id"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-if="meta.licensing.enabled"
                  v-show="tab == 'licensing'"
                  key="licensing-tab"
                >
                  <ProviderLicensing
                    @back="tab = false"
                    ref="licensing"
                    :provider="provider"
                    locked
                  />
                  <TabButtons
                    @back="back()"
                    hide-next
                  />
                </div>
              </transition-group>
            </div>
          </v-container>
        </div>
      </v-col>
    </v-row>

    <VerticalSpacer :min-height="50" />
  </div>
</template>

<script>
import { scrollTo } from 'vuetify/lib/composables/goto';
import { formatAddress } from '@/shared/services/address';
import Api from '@/manager/services/bright_finder';
import AssetManager from '@/manager/components/provider/AssetManager.vue';
import ContactInformation from '@/shared/components/provider/ContactInformation.vue';
import EmergencyResponse from '@/shared/components/provider/EmergencyResponse.vue';
import EnrollmentCount from '@/shared/components/provider/EnrollmentCount.vue';
import EnrollmentSteps from '@/shared/components/provider/EnrollmentSteps.vue';
import PaymentDetails from '@/shared/components/provider/PaymentDetails.vue';
import ProgramList from '@/manager/components/provider/ProgramList.vue';
import ProgramOpenings from '@/shared/components/provider/ProgramOpenings.vue';
import ProviderDescription from '@/shared/components/provider/ProviderDescription.vue';
import ProviderHighlights from '@/shared/components/provider/ProviderHighlights.vue';
import ProviderHours from '@/shared/components/provider/ProviderHours.vue';
import ProviderImages from '@/shared/components/provider/ProviderImages.vue';
import ProviderLicensing from '@/shared/components/provider/ProviderLicensing.vue';
import ProviderOverview from '@/manager/components/provider/ProviderOverview.vue';
import ProviderTours from '@/manager/components/provider/ProviderTours.vue';
import StaffMembers from '@/shared/components/provider/StaffMembers.vue';
import TabButtons from '@/manager/components/provider/TabButtons.vue';
import TabHeader from '@/shared/components/navigation/TabHeader.vue';
import Terms from '@/shared/mixins/terms';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import VirtualTourGallery from '@/shared/components/provider/VirtualTourGallery.vue';

const VIRTUAL_TOUR_DESCRIPTION =
  'Families are interested in seeing your location. ' +
  'Upload a virtual tour video to allow families to check out your space.';

export default {
  compatConfig: { MODE: 2 },

  components: {
    AssetManager,
    ContactInformation,
    EmergencyResponse,
    EnrollmentCount,
    EnrollmentSteps,
    PaymentDetails,
    ProgramList,
    ProgramOpenings,
    ProviderDescription,
    ProviderHighlights,
    ProviderHours,
    ProviderImages,
    ProviderLicensing,
    ProviderOverview,
    ProviderTours,
    StaffMembers,
    TabButtons,
    TabHeader,
    VerticalSpacer,
    VirtualTourGallery,
  },

  mixins: [Terms],

  beforeRouteLeave(_to, _from, next) {
    this.$eventBus.$off('blur');
    next();
  },

  data() {
    return {
      requestProceedFromEnrollmentSteps: false,
      changed: false,
      enabledTabs: [],
      provider: null,
      meta: null,
      processing: false,
      progress: {},
      tab: this.$route.query.tab,
      VIRTUAL_TOUR_DESCRIPTION,
    };
  },

  computed: {
    totalProgress() {
      const totalItems = Object.keys(this.progress).length;
      const itemsCompleted = Object.values(this.progress).reduce((memo, number) => memo + number);
      return parseInt(itemsCompleted / totalItems, 10);
    },
  },

  onMounted() {
    this.tab = this.tab || (this.$vuetify.display.smAndDown ? false : '');
  },

  watch: {
    '$route.params.providerId': {
      immediate: true,
      handler() {
        this.provider = null;
        this.$eventBus.$off('blur');
        this.$eventBus.$on('blur', this.save);
        this.load();
      },
    },

    // this logic is to ensure that our state is reset when we visit the enrollment_steps tab
    // this is due to enrollment_steps WYSIWYGs needing their markdowns to be saved upon leaving the tab
    // and the mechanism we have around that tab now
    tab(newValue) {
      if (newValue === 'enrollment_steps') this.resetRequestProceedFromEnrollmentSteps();
    },
  },

  methods: {
    onMoveFromEnrollmentSteps() {
      this.next({ canProceedFromEnrollmentSteps: true });
    },

    resetRequestProceedFromEnrollmentSteps() {
      this.requestProceedFromEnrollmentSteps = false;
    },

    back() {
      const currentIndex = this.enabledTabs.indexOf(this.tab);

      if (currentIndex > 0) {
        return this.transition(this.enabledTabs[currentIndex - 1]);
      }

      return this.enabledTabs[0];
    },

    changePublished(newValue) {
      this.update({ published: newValue });
    },

    randomNumber() {
      return Math.random() * 100;
    },

    async confirmAvailability(message) {
      const response = await Api.provider.update(this.provider.id, {
        openings_confirmed: true,
      });
      this.$eventBus.$emit('chime', message);
      this.provider = response.data;
    },

    destroyAsset(asset) {
      Api.manager.provider.asset.destroy(this.provider.id, asset.id, () => {
        this.processing = false;
        this.$eventBus.$emit('chime', 'Photo removed.');
        this.$refs.images.load();
      });
    },

    getAppBarChip() {
      if (this.provider.closed_status === 'Open') return { color: 'green', text: 'Open' };
      return { color: 'red', text: this.provider.closed_status };
    },

    getEnabledTabs() {
      if (!this.meta) return [];

      const enabledTabs = [];
      if (this.meta.overview.enabled) enabledTabs.push('overview');
      if (this.meta.openings.enabled) enabledTabs.push('openings');
      if (this.meta.photos.enabled) enabledTabs.push('photos');
      if (this.meta.video.enabled) enabledTabs.push('video');
      if (this.meta.profile.enable_description) enabledTabs.push('description');
      enabledTabs.push('contact');
      if (this.meta.programs.enabled) enabledTabs.push('programs');
      if (this.meta.tours.enabled) enabledTabs.push('tours');
      if (this.meta.enrollment_steps.enabled) enabledTabs.push('enrollment_steps');
      if (this.meta.profile.enable_emergency_response) enabledTabs.push('emergency');
      enabledTabs.push('hours');
      if (this.meta.profile.enable_payment_details) enabledTabs.push('financial');
      if (this.meta.enrollment.enabled) enabledTabs.push('enrollment');
      if (this.meta.profile.enable_highlights) enabledTabs.push('highlights');
      if (this.meta.staff.enabled) enabledTabs.push('staff');
      if (this.meta.licensing.enabled) enabledTabs.push('licensing');
      return enabledTabs;
    },

    getStartingTabProgress() {
      const tabProgress = {};
      this.getEnabledTabs().forEach((tab) => {
        tabProgress[tab] = 0;
      });
      return tabProgress;
    },

    load() {
      Api.manager.provider.get(this.$route.params.providerId, (response) => {
        this.provider = response.data;
        this.meta = this.$store.state.schemas[this.provider.schema_id]?.meta;
        this.tabs = this.getEnabledTabs();
        this.progress = this.getStartingTabProgress();
        this.$store.commit('setCurrentPageTitle', this.provider.name);
        this.$store.commit('setAppBarChip', this.getAppBarChip());
        this.enabledTabs = this.getEnabledTabs();
        if (!this.tab) {
          if (this.meta?.overview.enabled) {
            this.tab ||= 'overview';
          } else {
            [this.tab] = this.enabledTabs;
          }
        }
      });
    },

    next({ canProceedFromEnrollmentSteps = false } = {}) {
      if (this.tab === 'enrollment_steps') {
        this.requestProceedFromEnrollmentSteps = true;

        if (canProceedFromEnrollmentSteps === false) {
          // Return immediately to prevent the wizard from moving forward
          return null;
        }
      }

      const currentIndex = this.enabledTabs.indexOf(this.tab);
      if (currentIndex < this.enabledTabs.length - 1) {
        return this.transition(this.enabledTabs[currentIndex + 1]);
      }
      return this.enabledTabs[this.enabledTabs.length - 1];
    },

    providerAddress() {
      if (!this.provider) return null;

      return formatAddress(this.provider.address1, this.provider.city);
    },

    save() {
      Api.provider.update(
        this.provider.id,
        this.provider,
        () => {
          this.$eventBus.$emit('chime', 'Saved');
          this.$store.commit('setAppBarChip', this.getAppBarChip());
        },
        (error) => {
          this.$eventBus.$emit('chime', error.response.data.errors.join('. '));
        },
      );
    },

    transition(newTab) {
      this.tab = null;
      this.$router.replace({ query: { tab: newTab } });
      setTimeout(() => {
        this.tab = newTab;
        setTimeout(() => {
          scrollTo(0);
        }, 100);
      }, 400);
    },

    update(newValue) {
      this.processing = true;
      Api.provider.update(
        this.provider.id,
        newValue,
        (response) => {
          this.processing = false;
          this.$eventBus.$emit('chime', 'Saved');
          this.provider = response.data;
        },
        (error) => {
          this.processing = false;
          this.$eventBus.$emit('chime', error.response.data.errors.join('. '));
        },
      );
    },
  },
};
</script>
