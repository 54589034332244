<template>
  <v-card
    border
    flat
    tile
  >
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col>
          <span class="fs-16 fw-600">
            {{ $t(schema?.name) }}
          </span>
        </v-col>
        <v-col class="d-flex justify-end">
          <template v-if="form">
            <v-btn
              @click="emit('open')"
              color="primary"
            >
              <span>{{ $t('Review') }}</span>
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              @click="emit('start')"
              color="primary"
            >
              <v-icon start> priority_high </v-icon>
              <span>{{ $t('Start') }}</span>
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { useStore } from 'vuex';

const props = defineProps({
  form: {
    type: Object,
    default: null,
  },
  schemaId: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['open', 'start']);

const store = useStore();
const schema = computed(() => {
  return store?.state.schemas[props.schemaId];
});
</script>
