<template>
  <div>
    <v-row class="mb-2 d-flex align-center">
      <v-col
        cols="12"
        lg="9"
      >
        <ActionMenu
          @click:action:change="changeProvider($event)"
          :button-block="$vuetify.display.mdAndDown"
          :button-title="
            providers.find((provider) => provider.id == providerId)?.name || 'Select a location'
          "
          :items="providerActionItems"
          button-class="me-2 bg-super-light-grey px-3 py-2 rounded"
          button-color="black"
          button-icon-side="right"
          button-text
        />

        <ActionMenu
          v-if="providerId"
          @click:action:change="changeProgram($event)"
          :button-block="$vuetify.display.mdAndDown"
          :button-title="selectedProgram?.name || `Select a ${terms.program.toLowerCase()}`"
          :items="programActionItems"
          button-class="me-2 bg-super-light-grey px-3 py-2 rounded"
          button-color="black"
          button-icon-side="right"
          button-text
        />

        <ActionMenu
          v-if="selectedProgram"
          @click:action:change="changeOperatingLog($event)"
          :button-block="$vuetify.display.mdAndDown"
          :button-title="selectedMonth || 'Select a month'"
          :items="operatingLogActionItems"
          button-class="bg-super-light-grey px-3 py-2 rounded"
          button-color="black"
          button-icon-side="right"
          button-text
        />
      </v-col>
      <v-col
        v-if="selectedOperatingLog"
        class="d-flex justify-end"
        cols="12"
        lg="3"
      >
        <v-btn
          @click="handleOpenOperatingLog"
          :block="$vuetify.display.mdAndDown"
          :loading="processing"
          :variant="!!selectedOperatingLog.submitted_at ? 'outlined' : 'flat'"
          class="me-3"
          color="primary"
        >
          <template v-if="selectedOperatingLog.submitted_at">
            <v-icon class="me-2"> check </v-icon>
            <span>
              {{ $t('Submitted') }}
            </span>
          </template>
          <template v-else>
            <span>
              {{ $t('Submit') }}
            </span>
          </template>
        </v-btn>

        <ActionMenu
          :items="operatingLogActions"
          button-icon="more_vert"
          label="Download Data"
        />
      </v-col>
    </v-row>

    <template v-if="selectedOperatingLog">
      <AttendanceLogEditor
        @delete:attendance-log="deleteAttendanceLog($event)"
        @queue:attendance-log-change="queueAttendanceLogChange($event)"
        @queue:attendance-log-question="queueAttendanceLogQuestion($event)"
        @queue:operating-log-change="queueOperatingLogChange($event)"
        :attendance-log-questions="attendanceLogQuestions"
        :attendance-log-schema="attendanceLogSchema"
        :attendance-logs="attendanceLogs"
        :attendance-totals="attendanceTotals"
        :daily-totals="dailyTotals"
        :errors="errors"
        :operating-log="selectedOperatingLog"
        :operating-log-schema="operatingLogSchema"
        :queued-attendance-log-answers="queuedAttendanceLogAnswers"
        :queued-attendance-log-changes="queuedAttendanceLogChanges"
        :queued-attendance-log-question-id="queuedAttendanceLogQuestionId"
        :queued-operating-log-changes="queuedOperatingLogChanges"
        :submit-revision="selectedOperatingLog?.submission_revision"
      />
    </template>

    <template v-else>
      <NullState hide-new-button />
    </template>

    <AttendanceLogQuestionDialog
      @close="clearAttendanceLogQuestionQueue"
      @save="updateAttendanceLogsAnswers"
      ref="attendanceLogQuestionDialog"
      :processing="processing"
      :schema-definition="attendanceLogSchema.definition"
    />

    <ResourceDialog
      @close="clearAttendanceChangeQueue"
      ref="attendanceLogStatusDialog"
      :processing="processing"
      :title="`Set ${terms.attendance} status`"
      closeable
      hide-actions
    >
      <template #form>
        <v-btn
          v-for="status in $store.state.config.attendance_statuses"
          @click="updateAttendanceLogsStatus(status)"
          :key="status.code"
          :color="status.color"
          :loading="processing"
          class="mb-2 me-2 px-4"
          size="x-large"
          variant="flat"
          block
          tile
        >
          {{ status.code }} - {{ status.title }}
        </v-btn>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @close="clearOperatingLogChangeQueue"
      ref="operatingLogStatusDialog"
      :processing="processing"
      title="Set operating status"
      closeable
      hide-actions
    >
      <template #form>
        <v-btn
          v-for="status in $store.state.config.operating_statuses"
          @click="updateOperatingLog(status)"
          :key="status.code"
          :color="status.color"
          :loading="processing"
          class="mb-2 me-2 px-4"
          size="x-large"
          variant="flat"
          block
          tile
        >
          {{ status.code }} - {{ status.title }}
        </v-btn>
      </template>
    </ResourceDialog>

    <OperatingLogEditor @change="handleLoadOperatingLogs" />

    <ChangesQueuedAlert
      @clear:attendance-log-changes="clearAttendanceChangeQueue"
      @clear:attendance-log-question="clearAttendanceLogQuestionQueue"
      @clear:attendance-log-status="clearAttendanceLog"
      @clear:operating-log-changes="clearOperatingLogChangeQueue"
      @clear:operating-log-status="clearOperatingLog"
      @edit:attendance-log-question="openAttendanceLogQuestionDialog"
      @edit:attendance-log-status="$refs.attendanceLogStatusDialog.open()"
      @edit:operating-log="$refs.operatingLogStatusDialog.open()"
      :queued-attendance-log-answers="queuedAttendanceLogAnswers"
      :queued-attendance-log-changes="queuedAttendanceLogChanges"
      :queued-operating-log-changes="queuedOperatingLogChanges"
      :show-attendance-log-change-queue="showAttendanceLogChangeQueue"
      :show-attendance-log-question-queue="showAttendanceLogQuestionQueue"
      :show-operating-log-change-queue="showOperatingLogChangeQueue"
    />
  </div>
</template>

<script>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import AttendanceLogEditor from '@/shared/components/attendance-logs/AttendanceLogEditor.vue';
import AttendanceLogQuestionDialog from '@/shared/components/attendance-logs/AttendanceLogQuestionDialog.vue';
import ChangesQueuedAlert from '@/shared/components/ChangesQueuedAlert.vue';
import NullState from '@/shared/components/NullState.vue';
import OperatingLogEditor from '@/shared/components/OperatingLogEditor.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { validateAttendance } from '@/shared/services/attendance-validation';

import API from '@/shared/mixins/api';
import Attendance from '@/shared/mixins/attendance';
import RouterHelper from '@/shared/mixins/RouterHelper';
import Terms from '@/shared/mixins/terms';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ActionMenu,
    AttendanceLogEditor,
    AttendanceLogQuestionDialog,
    ChangesQueuedAlert,
    NullState,
    OperatingLogEditor,
    ResourceDialog,
  },

  mixins: [API, Attendance, RouterHelper, Terms],

  props: {
    subsidyProgramId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      processing: false,
      errors: [],
    };
  },

  computed: {
    attendanceLink() {
      if (!this.selectedOperatingLog) return null;

      const programName = this.programs.find(
        (program) => program.id === this.selectedOperatingLog.program_id,
      ).name;
      const filename = `${programName} ${this.terms.attendance} for ${this.selectedOperatingLog.year}-${this.selectedOperatingLog.month}`;
      return this.scopedApi().attendance_log.downloadUrl(filename, this.selectedOperatingLog.id);
    },

    operatingLogLink() {
      if (!this.selectedOperatingLog) return null;

      const programName = this.programs.find(
        (program) => program.id === this.selectedOperatingLog.program_id,
      ).name;
      const filename = `${programName} ${this.terms.attendance} for ${this.selectedOperatingLog.year}-${this.selectedOperatingLog.month}`;
      return this.scopedApi().operating_log.downloadUrl(this.selectedOperatingLog.id, filename);
    },

    operatingLogActions() {
      if (this.selectedOperatingLog) {
        return [
          { href: this.attendanceLink, title: `Download ${this.terms.attendance}` },
          { href: this.operatingLogLink, title: 'Download Form' },
        ];
      }

      return [];
    },

    operatingLogActionItems() {
      return this.operatingLogs.map((ol) => ({
        event: 'change',
        operatingLogId: ol.id,
        title: [this.$a.assets.months[ol.month], ol.year].join(' '),
      }));
    },

    providerActionItems() {
      return this.providers.map((provider) => ({
        event: 'change',
        title: provider.name,
        providerId: provider.id,
      }));
    },

    programActionItems() {
      return this.programs.map((program) => ({
        event: 'change',
        title: program.name,
        programId: program.id,
      }));
    },

    readonly() {
      return !!this.selectedOperatingLog?.submitted_at;
    },
  },

  watch: {
    subsidyProgramId() {
      this.providerId = null;
      this.selectedProgram = null;
      this.selectedOperatingLog = null;
      this.loadProviders();
    },
  },

  created() {
    this.loadProviders();
    this.loadAttendanceLogQuestions();
  },

  methods: {
    handleLoadOperatingLogs(operatingLog) {
      this.loadOperatingLogs(operatingLog);
      this.changeOperatingLogById(operatingLog.id);
    },

    deleteAttendanceLog(log) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (
        confirm(
          `Are you sure you want to remove the ${this.terms.attendance} record from this classroom for ${log.name} for this month?`,
        )
      ) {
        this.scopedApi().attendance_log.destroy(log.id, () => this.loadAttendanceLogs());
      }
    },

    async handleOpenOperatingLog() {
      this.errors = validateAttendance(
        this.attendanceLogs,
        this.attendanceLogQuestions,
        !this.operatingLogSchema.meta.disable_daily_attendance,
      );

      if (this.errors.length > 0) {
        return this.$eventBus.$emit('error', [`${this.terms.attendance} log is not complete.`]);
      }

      return await this.updateQuery({ action: 'editOperatingLog' });
    },

    async loadProviders() {
      const providerFilters = {
        subsidy_program_id: this.subsidyProgramId,
      };
      const { data } = await this.scopedApi().provider.index(providerFilters);

      this.providers = data;
      this.loadPrograms();
    },

    async loadPrograms() {
      if (!this.providerId) return;

      this.attendanceLogs = [];
      this.operatingLogs = [];

      const programFilters = {
        subsidy_program_id: this.subsidyProgramId,
      };
      const { data } = await this.api.program.index(this.providerId, programFilters);
      this.programs = data;
    },

    // Used in attendance mixin and OperatingLogEditor
    scopedApi() {
      return this.api.manager;
    },
  },
};
</script>
