<template>
  <div>
    <div class="mxw-300 mx-auto">
      <v-btn
        @click="open"
        color="primary"
        size="x-large"
        block
        rounded
      >
        <v-icon start> add </v-icon>
        <span>
          {{ $t('Request') }}
        </span>
      </v-btn>
    </div>

    <v-dialog
      v-model="newDialogIsVisible"
      max-width="600"
      persistent
    >
      <v-card
        border
        flat
        tile
      >
        <v-card-title>{{ $t('New request') }}</v-card-title>

        <v-divider class="mb-6" />

        <v-card-text>
          <v-row
            class="mb-4"
            dense
          >
            <v-col
              class="fs-16"
              cols="12"
            >
              <span>
                {{ $t('Location') }}
              </span>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="providerId"
                @update:model-value="loadAssessmentPrograms"
                :aria-label="$t('Location')"
                :items="providers"
                item-title="name"
                item-value="id"
                placeholder="Location"
                variant="filled"
                hide-details
                hide-no-data
              />
            </v-col>
          </v-row>

          <v-row
            class="mb-2"
            dense
          >
            <v-col
              class="labeled-input"
              cols="12"
            >
              {{ $t('Type') }}
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="assessmentProgram"
                :aria-label="$t('Type')"
                :items="assessmentPrograms"
                item-title="name"
                item-value="id"
                variant="filled"
                hide-details
                return-object
              />
            </v-col>
          </v-row>

          <v-alert
            v-if="assessmentProgram?.provider_request_disclaimer"
            color="primary"
            type="info"
          >
            <span>
              {{ $t(assessmentProgram.provider_request_disclaimer) }}
            </span>
          </v-alert>
        </v-card-text>

        <v-divider class="mt-4 mb-6" />

        <v-card-actions>
          <v-btn
            @click="newDialogIsVisible = false"
            size="x-large"
            variant="text"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="draft()"
            :disabled="!providerId || !assessmentProgram"
            :loading="processing"
            color="primary"
            size="x-large"
          >
            {{ $t('Create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template v-if="assessmentProgram?.request_form_schema_id">
      <FormDialog
        @save="create"
        ref="requestDialog"
        :provider-id="providerId"
        :schema-id="assessmentProgram.request_form_schema_id"
      />
    </template>
  </div>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import FormDialog from '@/shared/components/form/FormDialog.vue';
import { sortBy } from 'lodash';
import { useRouter } from 'vue-router';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const router = useRouter();
const store = useStore();

const assessmentProgram = ref(null);
const assessmentPrograms = ref([]);
const providerId = ref(null);
const newDialogIsVisible = ref(false);
const processing = ref(false);
const providers = ref([]);

// Template refs
const requestDialog = ref(null);

watch(providerId, (newValue, _oldValue) => loadAssessmentPrograms(newValue));

function create(form) {
  processing.value = true;
  Api.manager.assessment.create(
    { provider_id: providerId.value, schema_id: assessmentProgram.value.id },
    (response) => {
      processing.value = false;
      newDialogIsVisible.value = false;

      if (form) {
        Api.manager.provider.form.update(
          providerId.value,
          form.id,
          { assessment_id: response.data.id },
          () => {
            router.push({
              name: 'AssessmentShow',
              params: { assessmentId: response.data.id },
            });
          },
        );
      } else {
        router.push({
          name: 'AssessmentShow',
          params: { assessmentId: response.data.id },
        });
      }
    },
    (error) => {
      processing.value = false;
      eventBus.error(error);
    },
  );
}

function draft() {
  const failedCriteria = assessmentProgram.value?.eligibility
    .filter((eligibility) => !eligibility.eligible)
    .map((eligibility) => eligibility.message);

  if (failedCriteria.length > 0) return eventBus.error(failedCriteria);

  if (assessmentProgram.value?.request_form_schema_id) {
    requestDialog.value.open();
  } else {
    create();
  }

  return null;
}

function open() {
  providerId.value = null;
  assessmentProgram.value = null;
  newDialogIsVisible.value = true;
}

async function load() {
  const { data } = await Api.manager.provider.index();
  providers.value = data;
}

async function loadAssessmentPrograms(providerId) {
  const response = await Api.manager.provider.assessment_program.index(providerId);
  assessmentPrograms.value = response.data
    .filter((assessmentProgram) => !!store.state.schemas[assessmentProgram.id])
    .filter(
      (assessmentProgram) =>
        store.state.schemas[assessmentProgram.id].meta.enable_provider_requests,
    );

  assessmentPrograms.value = sortBy(assessmentPrograms.value, [(assessment) => assessment.name]);
}

onMounted(load);
</script>
