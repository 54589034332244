import Api from '@/shared/services/bright_finder';
import { useRouter } from 'vue-router';

export default function useAssessment(
  attachments,
  assessment,
  documentQuery,
  questions,
  schema,
  selectedSheet,
) {
  const router = useRouter();
  const currentStageId = ref(null);

  const currentStage = computed(() => {
    if (schema.value) {
      return schema.value.stages[currentStageIndex.value] || {};
    }
    return {};
  });

  const currentStageIndex = computed(() => {
    if (schema.value && assessment.value) {
      const index = schema.stages.findIndex((stage) => assessment.value.stage_id == stage.id);
      if (index == -1) {
        return 0;
      }
      return index;
    }
    return 0;
  });

  const queriedAttachments = computed(() => {
    if (documentQuery.vavlue) {
      return attachments.value.filter((attachment) =>
        attachment.file.metadata.filename.toLowerCase().includes(documentQuery.value.toLowerCase()),
      );
    }
    return attachments;
  });

  function selectSheet(ws) {
    router.push({ query: { worksheet_id: ws.id } });
  }

  function validateQuestions(allQuestions, callback) {
    const assertions = allQuestions.map((question) => {
      if (question.conditions.length > 0) {
        return {
          assertion: {
            conditions: question.conditions,
            value: selectedSheet.value ? selectedSheet.value : assessment.value,
          },
        };
      }
      return {
        assertion: {
          conditions: [],
          value: {},
        },
      };
    });

    Api.public_api.assertion.bulkCreate(assertions, (resp) => {
      const { results } = resp.data;
      allQuestions.forEach((question, index) => {
        question.valid = results[index].result;
      });
      questions.value = allQuestions.filter((question) => question.valid);

      if (callback) callback();
    });
  }

  return {
    currentStage,
    currentStageId,
    currentStageIndex,
    queriedAttachments,
    selectSheet,
    validateQuestions,
  };
}
