<template>
  <v-row class="mt-4 px-4 pt-4 bt-1 bc-extra-light-gray">
    <v-col cols="6">
      <v-btn
        v-if="!hideBack"
        @click="processBack"
        color="primary"
        variant="outlined"
      >
        {{ $t('Back') }}
      </v-btn>
    </v-col>
    <v-col
      v-if="!hideNext"
      class="d-flex justify-end"
      cols="6"
    >
      <v-btn
        @click="processNext"
        color="primary"
      >
        {{ $t('Next') }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script setup>
const { hideBack, hideNext } = defineProps({
  hideBack: {
    default: false,
    type: Boolean,
  },
  hideNext: {
    default: false,
    type: Boolean,
  },
});

const isProcessing = ref(false);

const emit = defineEmits(['back', 'next']);

function processBack(event) {
  isProcessing.value = true;
  event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
  emit('back');
  setTimeout(() => {
    isProcessing.value = false;
  }, 300);
}
function processNext(event) {
  isProcessing.value = true;
  event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
  emit('next');
  setTimeout(() => {
    isProcessing.value = false;
  }, 300);
}
</script>
