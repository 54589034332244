<template>
  <v-card-title>
    <v-row class="d-flex align-center">
      <v-col
        cols="12"
        sm="8"
      >
        <div>
          <p class="fw-500 fs-16">{{ $t('Section') }} {{ index + 1 }}</p>
          <p class="fw-600 fs-20">
            {{ $t(title) }}
          </p>
        </div>
      </v-col>

      <v-col
        :class="$vuetify.display.smAndUp ? 'ta-right' : ''"
        cols="12"
        sm="4"
      >
        <v-icon
          v-if="completed()"
          color="green"
          size="28"
        >
          check_circle
        </v-icon>

        <div v-else>
          <span class="fs-20 fw-500">
            {{ completedAssignmentCount() }} / {{ assignmentCount() }} {{ $t('Complete') }}
          </span>

          <template v-if="cohortLesson">
            <div v-if="cohortLesson.attributes?.start_date">
              <span class="fw-600">{{ $t('Starts') }}: </span>
              <LongDate
                :date="cohortLesson.attributes.start_date"
                short-month
              />
            </div>

            <div v-if="cohortLesson.attributes?.end_date">
              <span class="fw-600">{{ $t('Ends') }}: </span>
              <LongDate
                :date="cohortLesson.attributes.end_date"
                short-month
              />
            </div>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-card-title>
</template>

<script setup>
const props = defineProps({
  assignments: {
    type: Array,
    default: null,
  },
  assignmentCompletions: {
    type: Array,
    default: null,
  },
  cohortLesson: {
    type: Object,
    default: null,
  },
  index: {
    type: Number,
    default: null,
  },
  lessonCompletion: {
    type: Object,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
});

function assignmentCount() {
  return props.assignments.length;
}

function completedAssignmentCount() {
  return props.assignmentCompletions.filter(
    (assignmentCompletion) => assignmentCompletion.completed,
  ).length;
}

function completed() {
  return completedAssignmentCount() === assignmentCount() && props.lessonCompletion.completed;
}
</script>
