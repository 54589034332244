<template>
  <div
    v-if="$store.state.site"
    id="app"
    tabindex="-1"
  >
    <v-app>
      <template v-if="$store.state.pages.Welcome.content.alert && $route.name == 'Welcome'">
        <div
          class="pa-3 w-100 text-center bg-primary c-white"
          data-testid="alert"
        >
          <MarkdownContent :content="$store.state.pages.Welcome.content.alert" />
        </div>
      </template>

      <v-app-bar
        v-if="$route.name != 'Welcome'"
        id="navbar"
        elevation="0"
      >
        <router-link
          :to="$store.getters.defaultLandingPage()"
          aria-label="Home"
          class="px-3"
        >
          <v-img
            :class="[$vuetify.display.smAndDown ? 'logo-small' : 'logo-large', 'logo-padding']"
            :src="$store.state.brand.logo.small || $store.state.brand.logo.full"
            data-cy="manager_logo"
          />
        </router-link>

        <template v-if="$store.state.profile">
          <template v-if="$vuetify.display.mdAndUp && $route.meta.registration !== true">
            <v-btn
              v-if="$store.getters.hasBusinessAffiliation() && $store.state.pages.Provider.enabled"
              :active="false"
              :to="{ name: 'ProviderIndex' }"
              class="fs-15 px-6 h-100pc"
              data-cy="providers-button"
              size="x-large"
              variant="text"
              tile
            >
              {{ $t($store.state.pages.Provider.title || 'Locations') }}
            </v-btn>

            <v-btn
              v-if="
                $store.getters.hasBusinessAffiliation() &&
                $store.getters.hasProviderRequestableFormSchemas()
              "
              :active="false"
              :to="{ name: 'FormRequestIndex' }"
              class="fs-15 px-6 h-100pc"
              data-cy="requests-button"
              size="x-large"
              variant="text"
              tile
            >
              {{ $t($store.state.pages.Requests.title || 'Requests') }}
            </v-btn>

            <v-btn
              v-if="
                $store.getters.hasBusinessAffiliation() && $store.state.pages.Interested.enabled
              "
              :active="false"
              :to="{ name: 'InterestedIndex' }"
              class="fs-15 px-6 h-100pc"
              data-cy="interested-button"
              size="x-large"
              variant="text"
              tile
            >
              {{ $t($store.state.pages.Interested.title || 'Interested') }}
            </v-btn>

            <v-btn
              v-if="$store.getters.hasBusinessAffiliation() && $store.state.pages.Students.enabled"
              :active="false"
              :to="{ name: 'EnrollmentIndex' }"
              class="fs-15 px-6 h-100pc"
              data-cy="students-button"
              size="x-large"
              variant="text"
              tile
            >
              {{ $t($store.state.pages.Students.title || 'Students') }}
            </v-btn>

            <v-btn
              v-if="$store.getters.hasBusinessAffiliation() && $store.state.pages.Grants.enabled"
              :active="false"
              :to="{ name: 'GrantProgramIndex' }"
              class="fs-15 px-6 h-100pc"
              data-cy="grants-button"
              size="x-large"
              variant="text"
              tile
            >
              {{ $t($store.state.pages.Grants.title || 'Grants') }}
            </v-btn>

            <v-btn
              v-if="$store.state.pages.Learning.enabled && $store.state.profile.enable_courses"
              :active="false"
              :to="{ name: 'CourseIndex' }"
              class="fs-15 px-6 h-100pc"
              data-cy="courses-button"
              size="x-large"
              variant="text"
              tile
            >
              {{ $t($store.state.pages.Learning.title || 'Learning') }}
            </v-btn>

            <v-btn
              v-if="
                $store.getters.hasBusinessAffiliation() && $store.state.pages.Assessments.enabled
              "
              :active="false"
              :to="{ name: 'AssessmentIndex' }"
              class="fs-15 px-6 h-100pc"
              data-cy="assessments-button"
              size="x-large"
              variant="text"
              tile
            >
              {{ $t($store.state.pages.Assessments.title || 'Assessments') }}
            </v-btn>
          </template>

          <v-spacer />

          <LocaleSelector />

          <template
            v-if="
              $store.getters.hasBusinessAffiliation() &&
              $store.state.pages.Messaging.enabled &&
              $store.state.profile.business_confirmed
            "
          >
            <v-btn
              :active="false"
              :aria-label="$t('View messages')"
              :to="{ name: 'MessageIndex' }"
              class="c-black"
              data-cy="messages-button"
              variant="text"
              icon
            >
              <v-icon
                icon="forum"
                size="20"
              />
            </v-btn>
          </template>

          <v-menu
            ref="accountMenu"
            location="bottom left"
            role="mainmenu"
            open-on-click
          >
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                :aria-label="ariaName"
                class="c-black"
                variant="text"
                rounded
              >
                <template v-if="userProfileInitials">
                  <v-avatar
                    class="mr-2"
                    color="primary"
                    size="36px"
                  >
                    <v-img
                      v-if="profilePhoto"
                      :src="profilePhoto"
                      alt="Profile Photo"
                      size="small"
                    />
                    <span
                      v-else-if="userProfileInitials"
                      :aria-hidden="!showInitials"
                      class="pa-2 font-weight-bold text-white"
                    >
                      {{ userProfileInitials }}
                    </span>
                  </v-avatar>
                  <span
                    v-if="!showInitials"
                    class="profile-name"
                    >{{ $store.state.profile.name }}</span
                  >
                </template>
                <v-icon
                  v-else
                  icon="menu"
                />
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="$store.state.pages.Settings.enabled"
                :to="{ name: 'Settings' }"
                class="fw-500 fs-16 c-black"
              >
                <v-list-item-title>{{ $t('Settings') }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                :to="{ name: 'Profile' }"
                class="fw-500 fs-16 c-black"
              >
                <v-list-item-title>{{ $t('My profile') }}</v-list-item-title>
              </v-list-item>

              <template v-if="$store.state.pages.Navigation.links.faq">
                <v-list-item
                  :href="$store.state.pages.Navigation.links.faq"
                  class="fw-500 fs-16 c-black"
                >
                  <v-list-item-title>{{ $t('FAQ') }}</v-list-item-title>
                </v-list-item>
              </template>

              <v-list-item
                v-if="$store.getters.hasBusinessAffiliation()"
                :to="{ name: 'DocumentIndex' }"
                class="fw-500 fs-16 c-black"
              >
                <v-list-item-title>{{ $t('My Documents') }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                id="menu_terms"
                class="fw-500 fs-16 c-black"
                href="https://www.getbridgecare.com/terms-of-service"
                target="_blank"
                tracked
              >
                <v-list-item-title>{{ $t('Terms of Service') }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                id="menu_privacy"
                class="fw-500 fs-16 c-black"
                href="https://www.getbridgecare.com/privacy-policy"
                target="_blank"
                tracked
              >
                <v-list-item-title>{{ $t('Privacy Policy') }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="customLink1"
                id="link_1_link"
                :href="customLink1['url']"
                target="_blank"
              >
                <v-list-item-title>
                  {{ $t(customLink1['name']) }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="customLink2"
                id="link_2_link"
                :href="customLink2['url']"
                target="_blank"
              >
                <v-list-item-title>
                  {{ $t(customLink2['name']) }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                id="account_menu_signout"
                :to="{ name: 'SignOut' }"
                class="fw-500 fs-16 c-black"
                tracked
              >
                <v-list-item-title>{{ $t('Sign out') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-else>
          <v-spacer />

          <LocaleSelector />
        </template>
      </v-app-bar>

      <SnackBar
        v-model="chime"
        :centered="true"
        :text="chimeText"
        :timeout="chimeTime"
        rounded
      />

      <v-main class="bg-super-light-blue">
        <router-view v-slot="{ Component }">
          <component
            :is="Component"
            @chime="regularChime"
            @error="error"
            @replace="$router.push($event)"
          />
        </router-view>
      </v-main>

      <v-bottom-navigation
        v-if="
          $store.state.profile && $vuetify.display.smAndDown && $route.meta.registration !== true
        "
        grow
      >
        <v-btn
          v-if="$store.state.pages.Provider.enabled"
          :to="{ name: 'ProviderIndex' }"
          data-cy="bottom-providers-button"
        >
          {{ $t($store.state.pages.Provider.title || 'Locations') }}
          <v-icon icon="home" />
        </v-btn>

        <v-btn
          v-if="$store.getters.hasProviderRequestableFormSchemas()"
          :to="{ name: 'FormRequestIndex' }"
          data-cy="bottom-requests-button"
        >
          {{ $t($store.state.pages.Requests.title || 'Requests') }}
          <v-icon icon="article" />
        </v-btn>

        <v-btn
          v-if="$store.state.pages.Interested.enabled"
          :to="{ name: 'InterestedIndex' }"
          data-cy="bottom-interested-button"
        >
          {{ $t($store.state.pages.Interested.title || 'Interested') }}
          <v-icon icon="favorite" />
        </v-btn>

        <v-btn
          v-if="$store.state.pages.Students.enabled"
          :to="{ name: 'EnrollmentIndex' }"
          data-cy="bottom-students-button"
        >
          {{ $t($store.state.pages.Students.title || 'Students') }}
          <v-icon icon="face" />
        </v-btn>

        <v-btn
          v-if="$store.state.pages.Learning.enabled"
          :to="{ name: 'CourseIndex' }"
          data-cy="bottom-courses-button"
        >
          {{ $t($store.state.pages.Learning.title || 'Learning') }}
          <v-icon icon="book" />
        </v-btn>

        <v-btn
          v-if="$store.getters.hasBusinessAffiliation() && $store.state.pages.Grants.enabled"
          :to="{ name: 'GrantProgramIndex' }"
          data-cy="bottom-grants-button"
        >
          {{ $t($store.state.pages.Grants.title || 'Grants') }}
          <v-icon icon="account_balance" />
        </v-btn>

        <v-btn
          v-if="$store.getters.hasBusinessAffiliation() && $store.state.pages.Assessments.enabled"
          :to="{ name: 'AssessmentIndex' }"
          data-cy="bottom-assessments-button"
        >
          {{ $t($store.state.pages.Assessments.title || 'Assessments') }}
          <v-icon icon="star_rate" />
        </v-btn>
      </v-bottom-navigation>
      <UserSessionTimeout />
    </v-app>
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import chime from '@/shared/mixins/chime';
import useSegmentPageViews from '@/shared/composables/analytics/useSegmentPageViews';
import UserSessionTimeout from '@/shared/components/UserSessionTimeout.vue';
import LanguageHelper from '@/shared/mixins/LanguageHelper';
import LocaleSelector from '@/shared/components/LocaleSelector.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SnackBar from '@/shared/components/SnackBar.vue';
import SegmentService from '@/shared/services/analytics/SegmentService';
import { provide } from 'vue';
import { SEGMENT_PROVIDE_KEY } from '@/shared/composables/analytics/useSegment';

export default {
  compatConfig: { MODE: 2 },

  name: 'App',

  components: {
    LocaleSelector,
    MarkdownContent,
    ResourceDialog,
    UserSessionTimeout,
    SnackBar,
  },

  mixins: [API, chime, LanguageHelper],

  emits: ['click'],

  setup() {
    const segment = SegmentService.forProvider(window.analytics);
    provide(SEGMENT_PROVIDE_KEY, segment);
    useSegmentPageViews(segment);
  },

  data() {
    return {
      drawer: true,
      providers: [],
      tab: null,
    };
  },

  computed: {
    ariaName() {
      const defaultLabel = this.$t('Open account settings menu');
      const profileDetails = this.showInitials
        ? `${this.$t('Initials are')} ${this.userProfileInitials}, ${this.$t('full name is')} ${this.$store.state.profile.name}`
        : `${this.$t('Name is')} ${this.$store.state.profile.name}`;
      return `${defaultLabel}, ${profileDetails}`;
    },

    customLink1() {
      if (!this.$store.state.pages.Navigation) return null;
      if (!this.$store.state.pages.Navigation?.link_1_url) return null;
      if (!this.$store.state.pages.Navigation?.link_1_name) return null;

      return {
        name: this.$store.state.pages.Navigation.link_1_name,
        url: this.$store.state.pages.Navigation.link_1_url,
      };
    },

    customLink2() {
      if (!this.$store.state.pages.Navigation) return null;
      if (!this.$store.state.pages.Navigation?.link_2_url) return null;
      if (!this.$store.state.pages.Navigation?.link_2_name) return null;

      return {
        name: this.$store.state.pages.Navigation.link_2_name,
        url: this.$store.state.pages.Navigation.link_2_url,
      };
    },

    isSmallScreen() {
      return this.$vuetify.display.mdAndDown;
    },

    showInitials() {
      return this.isSmallScreen;
    },

    profilePhoto() {
      return this.$store.state.profile.photo;
    },

    userProfileInitials() {
      return this.$store.state.profile.name
        ?.split(' ')
        .map((string) => string[0])
        .join('')
        .slice(0, 2);
    },
  },

  created() {
    if (this.$route.query.chime) {
      this.regularChime(this.$route.query.chime);
    }
  },
};
</script>

<style scoped>
.logo-small {
  min-width: 100px;
}

.logo-large {
  min-width: 140px;
  min-height: 50px;
}

.logo-padding {
  padding-left: 10px;
}

.profile-name {
  min-width: 100px;
}

@media (max-width: 600px) {
  .profile-name {
    min-width: 100px;
  }
}
</style>
