<template>
  <div>
    <v-dialog
      v-model="visible"
      :max-width="1000"
      scrim="transparent"
    >
      <v-card
        v-if="enrollment"
        border
        flat
        tile
      >
        <EnrollmentDialogTitle
          @close="close"
          @edit:funding-sources="editFundingSources"
          @edit:program="$refs.programDialog.open()"
          :enrollment="enrollment"
          :funding-sources="fundingSources"
          :subsidy-program="subsidyProgram"
        />

        <v-card-text class="px-0 mt-4">
          <v-divider />

          <v-tabs
            v-model="tab"
            grow
          >
            <v-tab
              v-for="tabTitle in tabs"
              :key="tabTitle"
              :tab-value="tabTitle"
              class="c-black fs-16 ls-normal tt-none"
            >
              {{ $t(tabTitle) }}
            </v-tab>
          </v-tabs>

          <v-divider />

          <v-window
            v-model="tab"
            class="px-6 py-4"
          >
            <EnrollmentDialogFamilyTab
              :enrollment="enrollment"
              :subsidy-program="subsidyProgram"
            />
            <EnrollmentDialogNotesTab
              @save="saveNotes"
              :notes="enrollment.notes"
            />
            <EnrollmentDialogFormsTab
              v-if="showFormsTab"
              @change:enrolled-form="saveEnrolledForm"
              :accept-form="acceptForm"
              :enrolled-form="enrolledForm"
              :enrollment="enrollment"
              :subsidy-program="subsidyProgram"
            />
            <EnrollmentDialogDocumentsTab
              :enrollment="enrollment"
              :subsidy-program="subsidyProgram"
            />
          </v-window>

          <v-divider class="mt-6" />

          <v-btn
            @click="checkEndDate()"
            class="mt-4"
            color="red"
            variant="text"
            block
          >
            {{ $t(defaultUnenrolledAction) }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ResourceDialog
      v-if="enrollment"
      @save="saveProgram"
      ref="programDialog"
      :title="terms.program"
      close-on-save
      closeable
    >
      <template #form>
        <v-row>
          <LabeledSelect
            v-model="enrollment.program_id"
            :items="programs"
            :message="`What program or class will they ${terms.enroll.toLowerCase()} in?`"
            :multiple="false"
            cols="12"
            item-title="name"
            item-value="id"
          />
        </v-row>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @save="saveFundingSources"
      ref="editFundingSourceDialog"
      :fields="fundingSourceFields"
      title="Edit funding sources"
    />

    <ResourceDialog
      @save="unenrollWithoutEndDate"
      ref="noEndDateUnenroll"
      :max-width="800"
      title="Confirm unenrollment"
      close-on-save
    >
      <template #form>
        <v-row dense>
          <v-col>
            <p class="fs-16 mb-0">
              {{
                $t(
                  "By unenrolling before the start date, you're confirming the child never attended.",
                )
              }}
            </p>
          </v-col>
        </v-row>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @save="draftUnenroll"
      ref="endDateDialog"
      :max-width="800"
      title="Enter the last day the child attended the program"
      close-on-save
    >
      <template #form>
        <v-date-picker
          v-model="enrollmentEndDate"
          :landscape="$vuetify.display.mdAndUp"
          :max="maximumEnrollmentEndDate"
          :min="minimumEnrollmentEndDate"
          class="b-1 dense bc-primary"
          data-testid="enrollment-end-date-picker"
          full-width
        />
      </template>
    </ResourceDialog>

    <FormDialog
      v-if="subsidyProgram && subsidyProgram.unenroll_form_schema_id && enrollment"
      @save="unenroll"
      ref="unenrollDialog"
      :enrollment-id="enrollment.id"
      :group-id="enrollment.group_id"
      :provider-id="enrollment.provider_id"
      :schema-id="subsidyProgram.unenroll_form_schema_id"
      :subsidy-id="enrollment.subsidy_id"
    />
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import Statuses from '@/shared/mixins/statuses';
import Terms from '@/shared/mixins/terms';
import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';
import FormDialog from '@/shared/components/form/FormDialog.vue';
import LabeledSelect from '@/shared/components/form/LabeledSelect.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import EnrollmentDialogDocumentsTab from '@/manager/components/enrollment/EnrollmentDialogDocumentsTab.vue';
import EnrollmentDialogNotesTab from '@/manager/components/enrollment/EnrollmentDialogNotesTab.vue';
import EnrollmentDialogFormsTab from '@/manager/components/enrollment/EnrollmentDialogFormsTab.vue';
import EnrollmentDialogFamilyTab from '@/manager/components/enrollment/EnrollmentDialogFamilyTab.vue';
import EnrollmentDialogTitle from '@/manager/components/enrollment/EnrollmentDialogTitle.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    EnrollmentDialogDocumentsTab,
    EnrollmentDialogFamilyTab,
    EnrollmentDialogFormsTab,
    EnrollmentDialogNotesTab,
    EnrollmentDialogTitle,
    FormDialog,
    LabeledSelect,
    ResourceDialog,
  },

  mixins: [API, Statuses, Terms],

  props: {
    subsidyPrograms: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['change'],

  data() {
    return {
      acceptForm: null,
      defaultUnenrolledAction: null,
      defaultUnenrolledStatus: null,
      enrolledForm: null,
      enrollment: null,
      enrollmentEndDate: null,
      fundingSources: [],
      maximumEnrollmentEndDate: null,
      minimumEnrollmentEndDate: null,
      programs: [],
      processing: false,
      subsidyProgram: null,
      tab: 0,
      visible: false,
    };
  },

  computed: {
    fundingSourceFields() {
      return [
        {
          text: 'Funding sources',
          value: 'funding_source_ids',
          itemText: 'name',
          itemValue: 'id',
          items: this.fundingSources,
          multiple: true,
        },
      ];
    },

    showFormsTab() {
      return (
        this.subsidyProgram?.enrolled_form_schema_id ||
        (this.subsidyProgram?.accept_form_schema_id && this.enrollment?.accept_form_id)
      );
    },

    tabs() {
      const tabs = ['Family', 'Notes'];
      if (this.showFormsTab) tabs.push('Forms');
      tabs.push('Documents');

      return tabs;
    },

    today() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
    },
  },

  methods: {
    checkEndDate() {
      if (
        this.minimumEnrollmentEndDate &&
        this.maximumEnrollmentEndDate &&
        this.minimumEnrollmentEndDate > this.maximumEnrollmentEndDate
      ) {
        this.$refs.noEndDateUnenroll.open();
      } else {
        this.$refs.endDateDialog.open();
      }
    },
    draftUnenroll() {
      const msg = this.$t(
        `Are you certain you want to ${this.defaultUnenrolledAction.toUpperCase()} this student?`,
      );
      // eslint-disable-next-line no-alert, no-restricted-globals
      if (!confirm(msg)) return;

      if (this?.subsidyProgram?.unenroll_form_schema_id) {
        this.$refs.unenrollDialog.open();
      } else {
        this.unenroll();
      }
    },

    editFundingSources() {
      this.$refs.editFundingSourceDialog.open(JSON.parse(JSON.stringify(this.enrollment)));
    },

    async saveFundingSources(newVal) {
      this.processing = true;

      const params = {
        funding_source_ids: newVal.funding_source_ids,
      };
      const response = await this.api.manager.enrollment.update(this.enrollment.id, params);
      if (response?.status !== 200) {
        this.processing = false;
        return false;
      }

      this.enrollment = response.data;
      this.$emit('change');
      this.$refs.editFundingSourceDialog.close();
      this.processing = false;

      return true;
    },

    getMinimumEndDate() {
      if (!this.enrollment.start_date) return undefined;

      return this.$vuetify.date.parseISO(this.enrollment.start_date);
    },

    close() {
      this.$refs.programDialog.close();
      this.visible = false;
    },

    async loadAcceptForm() {
      this.acceptForm = (
        await this.api.manager.provider.form.get(
          this.enrollment.provider_id,
          this.enrollment.accept_form_id,
        )
      ).data;
    },

    async loadEnrolledForm() {
      this.enrolledForm = (
        await this.api.manager.provider.form.get(
          this.enrollment.provider_id,
          this.enrollment.enrolled_form_id,
        )
      ).data;
    },

    async loadPrograms() {
      const filters = { subsidy_program_id: this.subsidyProgram?.id };
      const { data } = await this.api.program.index(this.enrollment.provider_id, filters);
      this.programs = data;
    },

    async open(enrollment) {
      this.tab = 0;
      this.programs = [];
      this.acceptForm = null;
      this.enrolledForm = null;
      this.enrollment = enrollment;
      if (this.enrollment.enrolled_form_id) this.loadEnrolledForm();
      if (this.enrollment.accept_form_id) this.loadAcceptForm();

      this.subsidyProgram = this.subsidyPrograms.find(
        (subsidyProgram) => subsidyProgram.id === this.enrollment.subsidy_program_id,
      );
      this.defaultUnenrolledAction =
        this.subsidyProgram?.default_unenrolled_action || this.$t(this.terms.unenroll);
      this.defaultUnenrolledStatus =
        this.subsidyProgram?.default_unenrolled_status ||
        this.$t(this.getStatusText(ENROLLMENT_STATUSES.UNENROLLED));

      this.enrollmentEndDate = this.today;
      this.maximumEnrollmentEndDate = this.today;
      this.minimumEnrollmentEndDate = this.getMinimumEndDate();

      this.visible = true;
      this.loadPrograms();

      if (!this.subsidyProgram?.enable_enrollment_funding_sources) return;

      this.api.public_api.organization.funding_source.index(
        {
          owner_id: this.subsidyProgram.id,
          enrollment_assignable: true,
        },
        (response) => {
          this.fundingSources = response.data;
        },
      );
    },

    async saveEnrolledForm(newForm) {
      const response = await this.api.manager.enrollment.update(this.enrollment.id, {
        enrolled_form_id: newForm.id,
      });
      if (response?.status !== 200) return;

      this.enrollment = response.data;
      this.loadEnrolledForm();
      this.$emit('change');
    },

    async saveNotes(newVal) {
      if (this.enrollment) {
        await this.api.manager.enrollment.update(this.enrollment.id, { notes: newVal });
        this.enrollment.notes = newVal;
      } else {
        console.error('Failed to update');
      }
    },

    async saveProgram() {
      this.processing = true;
      const resp = await this.api.manager.enrollment.update(this.enrollment.id, {
        program_id: this.enrollment.program_id,
      });
      this.processing = false;
      if (resp?.status !== 200) return;

      this.$emit('change');
      this.enrollment = resp.data;
    },

    async unenroll() {
      const params = {
        status: this.defaultUnenrolledStatus,
        end_date: this.enrollmentEndDate ? this.$vuetify.date.toISO(this.enrollmentEndDate) : null,
      };

      const response = await this.api.manager.enrollment.update(this.enrollment.id, params);
      if (response?.status !== 200) return;

      this.close();
      this.$emit('change');
    },

    async unenrollWithoutEndDate() {
      if (this?.subsidyProgram?.unenroll_form_schema_id) {
        this.enrollmentEndDate = null;
        this.$refs.unenrollDialog.open();
      } else {
        const params = {
          status: this.defaultUnenrolledStatus,
          end_date: null,
        };

        const response = await this.api.manager.enrollment.update(this.enrollment.id, params);
        if (response?.status !== 200) return;
      }

      this.close();
      this.$emit('change');
    },
  },
};
</script>
