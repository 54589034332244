<template>
  <div class="w-100pc h-100pc oy-hidden bg-super-light-blue">
    <v-row
      class="bg-super-light-blue w-100pc h-100pc oy-hidden bottom-0 p-absolute"
      style="padding-top: 65px"
      no-gutters
    >
      <v-col
        v-show="$vuetify.display.mdAndUp || activeThread == false"
        class="h-100pc oy-hidden-scroll br-1 bc-extra-light-gray bg-white pb-16"
        cols="12"
        md="4"
      >
        <div class="pa-4 bb-1 bc-extra-light-gray d-flex align-center">
          <v-text-field
            v-model="query"
            :aria-label="$t('Search by name')"
            :placeholder="$t('Search by name')"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
            tile
          />
          <v-btn
            @click="load"
            :loading="processing"
            class="ms-1"
            size="small"
            variant="text"
            icon
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </div>
        <div
          v-if="$store.state.pages.Messaging.features.enable_support"
          @click="switchThread()"
          class="pa-4 bb-1 bc-extra-light-gray d-flex align-center pointer"
          data-cy="support-thread"
          data-testid="support-thread"
        >
          <v-icon
            class="me-2"
            size="26"
          >
            support_agent
          </v-icon>
          <div>
            <div
              v-t="$store.state.brand.organization_name"
              class="fs-18 fw-500"
            />
            <div
              v-t="'Need assistance? Message one of our staff members.'"
              class="fs-16 c-light-black"
            />
          </div>
          <v-spacer />
          <v-icon
            v-if="displayNewSupportMessageIcon"
            class="mx-1"
            color="green"
          >
            circle
          </v-icon>
        </div>
        <div
          v-for="meta in filteredThreads"
          @click="switchThread(meta)"
          :key="metaKey(meta)"
          class="pa-4 bb-1 bc-extra-light-gray d-flex align-center pointer"
          data-cy="parent-thread"
        >
          <v-icon
            v-if="meta.type && meta.type === 'assessment'"
            class="me-2"
            size="26"
          >
            school
          </v-icon>
          <v-icon
            v-else
            class="me-2"
            size="26"
          >
            escalator_warning
          </v-icon>
          <div>
            <div
              v-text="metaName(meta)"
              class="fs-18 fw-500"
            />
            <MarkdownContent
              :content="getMessages(meta).text"
              class="fs-16 c-light-black h-20 oy-hidden"
            />
          </div>
          <v-spacer />
          <v-icon
            v-if="checkForUnreadGroupMessages(meta)"
            class="mx-1"
            color="green"
          >
            circle
          </v-icon>
        </div>
      </v-col>

      <v-col
        v-show="$vuetify.display.mdAndUp || activeThread != false"
        class="h-100pc oy-hidden-scroll p-relative bg-white"
        cols="12"
        data-cy="conversation"
        md="8"
      >
        <div class="h-100pc w-100pc">
          <MessageThreadHeader
            @back="switchThread(false)"
            :assessment="loadAssessment(activeThread)"
            :subtitle="$t(activeThreadSubtitle)"
            :title="$t(activeThreadTitle)"
          />
          <div
            v-if="$store.state.pages.Messaging.features.enable_support && activeThread == null"
            id="messages_list"
            key="org-messages"
            :class="messageListClass"
            style="bottom: 220px"
          >
            <MessageItem
              v-for="message in messagesWithOrg"
              :key="message.id"
              :message="message"
            />
          </div>
          <div
            v-if="filteredActiveThread"
            id="messages_list"
            :class="messageListClass"
            style="bottom: 220px"
          >
            <MessageItem
              v-for="message in nonOrgMessages[metaKey(filteredActiveThread)]"
              :key="message.id"
              :message="message"
            />
          </div>
          <div
            v-if="activeThread || $store.state.pages.Messaging.features.enable_support"
            class="bc-extra-light-gray bg-white w-100pc bottom-0 p-absolute"
            data-cy="message-composer"
          >
            <div class="pa-2">
              <v-row dense>
                <v-col>
                  <v-card
                    style="background: rgba(0, 0, 0, 0.04)"
                    flat
                    tile
                  >
                    <v-btn
                      @click="$refs.attachmentDialog.open({})"
                      data-cy="attach-button"
                      style="background: transparent"
                      variant="flat"
                    >
                      <v-icon start> attachment </v-icon>
                      <span v-t="'Attach document'" />
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
              <v-divider />
              <AttachmentDialog
                @save="createAttachmentMessages"
                @upload="uploadedAttachments.push($event)"
                ref="attachmentDialog"
                :processing="processing"
              />
              <v-textarea
                v-model="newMessageText"
                :aria-label="$t('Enter your message')"
                class="mb-2"
                data-cy="message-textarea"
                rows="3"
                variant="filled"
                hide-details
                tile
              />
              <v-btn
                @click="createMessage"
                :disabled="submitDisabled"
                :loading="processing"
                color="primary"
                data-cy="send-message-button"
                size="x-large"
                block
              >
                {{ $t('Send message') }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import AttachmentDialog from '@/shared/components/attachments/AttachmentDialog.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import MessageItem from '@/shared/components/MessageItem.vue';
import MessageThreadHeader from '@/shared/components/MessageThreadHeader.vue';
import { useRoute } from 'vue-router/dist/vue-router';
import { useDisplay } from 'vuetify';
import { useStore } from 'vuex';

const display = useDisplay();
const store = useStore();
const route = useRoute();

const activeThread = ref(display.mdAndUp ? null : false);
const assessmentThreads = ref([]);
const groupThreads = ref([]);
const messageListClass = ref(
  'bg-white w-100pc d-flex flex-column pa-4 top-100 oy-scroll p-absolute',
);
const messages = ref([]);
const messagesWithOrg = ref([]);
const nonOrgMessages = ref({});
const newMessageText = ref(null);
const processing = ref(false);
const query = ref(null);
const uploadedAttachments = ref([]);

// Template Ref

const attachmentDialog = ref(null);

const filteredActiveThread = computed(() => {
  return filteredThreads.value?.find(
    (thread) =>
      thread.group?.id === activeThread.value?.group?.id &&
      thread.provider?.id === activeThread.value?.provider?.id,
  );
});

const activeThreadSubtitle = computed(() => {
  if (activeThread.value && activeThread.value.type === 'assessment') {
    return 'Open this assessment';
  }
  if (activeThread.value && activeThread.value.group && activeThread.value.provider) {
    return `Family interested in ${activeThread.value.provider.address}`;
  }
  if (store.state.pages.Messaging.features.enable_support) {
    return 'Support staff will message you on this channel.';
  }
  return 'Messages you receive from families will appear here.';
});

const activeThreadTitle = computed(() => {
  if (activeThread.value && activeThread.value.type === 'assessment') {
    return `${activeThread.value.schema_name} - ${activeThread.value.name}`;
  }
  if (activeThread.value && activeThread.value.group && activeThread.value.provider) {
    return activeThread.value.group.name;
  }
  if (store.state.pages.Messaging.features.enable_support) {
    return store.state.brand.organization_name;
  }
  return 'No messages';
});

const displayNewSupportMessageIcon = computed(() => {
  if (!messagesWithOrg.value.length) return false;

  const lastIndex = messagesWithOrg.value.length - 1;
  return (
    messagesWithOrg.value.length > 0 &&
    [messagesWithOrg.value[lastIndex]].some(
      (message) => !message.meta.member.is_provider && !message.read_at,
    )
  );
});

const filteredThreads = computed(() => {
  if (query.value && query.value !== '') {
    const filteredGroups = groupThreads.value.filter(
      (thread) => thread.group.name && thread.group.name.toLowerCase().includes(query.value),
    );
    const filteredAssessments = assessmentThreads.value.filter(
      (thread) => thread.name && thread.name.toLowerCase().includes(query.value),
    );
    return filteredGroups.concat(filteredAssessments);
  }
  return groupThreads.value.concat(assessmentThreads.value);
});

const submitDisabled = computed(() => {
  return !newMessageText.value || newMessageText.value.trim().length === 0;
});

onMounted(load);

function checkForUnreadGroupMessages(meta) {
  if (meta.provider) {
    [getMessages(meta)].some((message) => !message.meta.member.is_provider && !message.read_at);
  } else if (meta.type && meta.type === 'assessment') {
    [getMessages(meta)].some((message) => !message.read_at);
  }
}

function createMessage() {
  processing.value = true;

  let params;
  if (activeThread.value) {
    if (activeThread.value.type && activeThread.value.type === 'assessment') {
      params = { text: newMessageText.value, assessment_id: activeThread.value.id };
    } else if (activeThread.value.provider && activeThread.value.group) {
      params = {
        text: newMessageText.value,
        provider_id: activeThread.value.provider.id,
        group_id: activeThread.value.group.id,
      };
    }
  } else {
    params = { text: newMessageText.value };
  }

  Api.manager.message.create(params, (resp) => {
    messages.value.push(resp.data);
    processing.value = false;
    newMessageText.value = null;
    sortMessages();
    scrollToBottom();
  });
}

function getMessages(meta) {
  let key;
  if (meta.group && meta.provider) {
    key = [meta.group.id, meta.provider.id].join('');
  }
  if (meta.type && meta.type === 'assessment') {
    key = meta.id;
  }
  return nonOrgMessages.value[key][nonOrgMessages.value[key].length - 1];
}

function load() {
  assessmentThreads.value = [];
  messages.value = [];
  groupThreads.value = [];
  messagesWithOrg.value = [];
  nonOrgMessages.value = {};
  processing.value = true;
  Api.manager.message.index({}, (resp) => {
    messages.value = resp.data;
    processing.value = false;
    sortMessages();
    scrollToBottom();
  });
}

function loadAssessment(activeThread) {
  return activeThread?.type === 'assessment' ? activeThread : null;
}

async function createAttachmentMessages() {
  processing.value = true;

  let params;
  if (activeThread.value) {
    if (activeThread.value?.type === 'assessment') {
      params = { assessment_id: activeThread.value.id };
    } else if (activeThread.value.provider && activeThread.value.group) {
      params = {
        provider_id: activeThread.value.provider.id,
        group_id: activeThread.value.group.id,
      };
    }
  } else {
    params = { group_id: null };
  }

  await Promise.all(
    uploadedAttachments.value.map(async (attachment) => {
      const asset = attachment;
      asset.group_id = params.group_id;
      const { data } = await Api.member.attachment.create(asset);
      const resp = await Api.manager.message.promiseCreate({
        ...params,
        attachment_id: data.id,
      });
      messages.value.push(resp.data);
    }),
  );

  attachmentDialog.value.close();
  uploadedAttachments.value = [];
  processing.value = false;
  newMessageText.value = null;
  sortMessages();
  scrollToBottom();
}

function metaKey(meta) {
  if (meta.group && meta.provider) return [meta.group.id, meta.provider.id].join('');
  if (meta.type && meta.type === 'assessment') return meta.id;
  return undefined;
}

function metaName(meta) {
  if (meta.group && meta.provider) return meta.group?.name;
  if (meta.type && meta.type === 'assessment') return `${meta.schema_name} - ${meta.name}`;
  return undefined;
}

function scrollToBottom() {
  nextTick(() => {
    const list = document.getElementById('messages_list');
    if (list !== null) {
      list.scrollTop = list.scrollHeight;
    }
  });
}

function sortMessages() {
  messages.value.forEach((message) => {
    let key;
    if (message.assessment_id) {
      key = message.assessment_id;
      if (nonOrgMessages.value[message.assessment_id] === undefined) {
        nonOrgMessages.value[message.assessment_id] = [];
        if (!assessmentThreads.value.includes(message.meta.assessment)) {
          assessmentThreads.value.push(message.meta.assessment);
        }
      }
      if (!nonOrgMessages.value[message.assessment_id].includes(message)) {
        nonOrgMessages.value[message.assessment_id].push(message);
      }
    } else if (message.group_id == null) {
      if (!messagesWithOrg.value.includes(message)) {
        messagesWithOrg.value.push(message);
      }
    } else {
      key = [message.group_id, message.provider_id].join('');
      if (nonOrgMessages.value[key] === undefined) {
        nonOrgMessages.value[key] = [];
        if (
          !groupThreads.value.includes({
            group: message.meta.group,
            provider: message.meta.provider,
          })
        ) {
          groupThreads.value.push({
            group: message.meta.group,
            provider: message.meta.provider,
          });
        }
      }

      if (!nonOrgMessages.value[key].includes(message)) {
        nonOrgMessages.value[key].push(message);
      }
    }
  });

  if (activeThread.value && activeThread.value.group && activeThread.value.provider) {
    switchThread(
      filteredThreads.value.find(
        (thread) =>
          thread.provider &&
          thread.provider.id === activeThread.value.provider.id &&
          thread.group.id === activeThread.value.group.id,
      ),
    );
  }

  if (activeThread.value && activeThread.value.type === 'assessment') {
    switchThread(assessmentThreads.value.find((thread) => thread.id === activeThread.value.id));
  }

  if (route.query.provider_id && route.query.group_id) {
    switchThread(
      filteredThreads.value.find(
        (thread) =>
          thread.provider.id === route.query.provider_id &&
          thread.group.id === route.query.group_id,
      ),
    );
  } else if (!store.state.pages.Messaging.features.enable_support && activeThread.value == null) {
    switchThread(filteredThreads.value[0]);
  }
}

function switchThread(thread) {
  activeThread.value = thread;
  if (thread !== false) {
    scrollToBottom();
    let msg;
    if (thread) {
      msg = getMessages(activeThread.value);
    } else {
      msg = messagesWithOrg.value[messagesWithOrg.value.length - 1];
    }
    if (!msg.read_at) {
      Api.manager.message.get(msg.id, () => {});
    }
    msg.read_at = true;
  }
}
</script>
