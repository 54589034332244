<template>
  <div>
    <FormQuestion
      :subtitle="subsidyProgram.home_address_subtitle"
      :title="subsidyProgram.home_address_title || 'What is your family\'s primary home address.'"
      class="mb-4"
      dense
      expanded
      hide-actions
      paddingless
    >
      <v-row>
        <LabeledTextfield
          :model-value="group.home_address"
          :schema-id="group.schema_id"
          field="home_address"
          message="Address"
          dense
        />
        <LabeledTextfield
          :model-value="group.home_city"
          :schema-id="group.schema_id"
          field="home_city"
          md="4"
          message="City"
          dense
        />
        <LabeledSimpleSelect
          :items="$a.assets.states"
          :model-value="group.home_state"
          :schema-id="group.schema_id"
          field="home_state"
          md="4"
          message="State"
          placeholder="Select one"
          dense
        />
        <LabeledTextfield
          :model-value="group.home_zip"
          :schema-id="group.schema_id"
          field="home_zip"
          md="4"
          message="Zip"
          dense
        />
      </v-row>
      <v-row v-if="shouldShowHomelessnessQuestion()">
        <v-checkbox
          :disabled="true"
          :label="
            $t(subsidyProgram.homeless_attestation || 'My family is experiencing homelessness.')
          "
          :model-value="group.experiencing_homelessness"
        />
      </v-row>
    </FormQuestion>

    <v-divider class="mb-4" />

    <div>
      <FormQuestion
        :title="childData.name"
        class="mb-4"
        break-after
        dense
        hide-actions
        paddingless
      >
        <Child
          :initial-child="childData"
          dense
          prevent-update
          remove-disabled
        />
      </FormQuestion>

      <v-divider class="mb-4" />
    </div>

    <QuestionSet
      :attachment-group-id="group.id"
      :attachment-owner-id="subsidyProgram.id"
      :attachment-owner-type="'SubsidyProgram'"
      :attachments="attachments"
      :model-value="group"
      :questions="groupEligibilityQuestions"
      :schema="groupSchema.definition"
      attachment-readonly
      dense
      divided
      expanded
      hide-actions
      paddingless
    />

    <QuestionSet
      :key="childData.id + '-eligibility'"
      :header="$t('Child') + ' - ' + childData.first_name"
      :model-value="childData"
      :questions="childEligibilityQuestions"
      :schema="childSchema.definition"
      dense
      divided
      expanded
      hide-actions
      paddingless
    />

    <QuestionSet
      :attachment-group-id="group.id"
      :attachment-owner-id="subsidyProgram.id"
      :attachment-owner-type="'SubsidyProgram'"
      :attachments="attachments"
      :model-value="group"
      :questions="groupQuestions"
      :schema="groupSchema.definition"
      attachment-readonly
      dense
      divided
      expanded
      hide-actions
      paddingless
    />

    <QuestionSet
      :key="childData.id + '-other'"
      :header="$t('Child') + ' - ' + childData.first_name"
      :model-value="childData"
      :questions="childQuestions"
      :schema="childSchema.definition"
      dense
      divided
      expanded
      hide-actions
      paddingless
    />

    <div
      v-for="question in groupVerificationQuestions"
      :key="question.id"
    >
      <v-divider class="my-4" />

      <FormQuestion
        :subtitle="question.verification_subtitle"
        :title="question.verification_title"
        dense
        hide-actions
        paddingless
      >
        <AttachmentList
          :attachments="
            attachments.filter(
              (attachment) =>
                attachment.tag == question.id || attachment.tags.includes(question.id),
            )
          "
          :empty-label="$t('No documents available.')"
          dense
          hide-remove
        />
      </FormQuestion>
    </div>

    <div>
      <div
        v-for="question in childVerificationQuestions"
        :key="question.id + childData.id"
        no-print
      >
        <v-divider class="my-4" />

        <FormQuestion
          :subtitle="question.verification_subtitle"
          :title="question.verification_title"
          dense
          hide-actions
          paddingless
        >
          <AttachmentList
            :attachments="
              attachments.filter(
                (attachment) =>
                  attachment.tag == childData.id + question.id ||
                  (attachment.tags.includes(childData.id) && attachment.tags.includes(question.id)),
              )
            "
            :empty-label="$t('No documents available.')"
            dense
            hide-remove
          />
        </FormQuestion>
      </div>
    </div>

    <template v-if="subsidyProgram.verify_child">
      <div>
        <div no-print>
          <v-divider class="my-4" />
          <FormQuestion
            :header="$t('Child') + ' - ' + childData.first_name"
            :subtitle="subsidyProgram.verify_child_subtitle"
            :title="subsidyProgram.verify_child_title"
            dense
            hide-actions
            paddingless
          >
            <AttachmentList
              :attachments="
                attachments.filter(
                  (attachment) =>
                    attachment.tag == 'child-documents-' + childData.id ||
                    (attachment.tags.includes('child-documents') &&
                      attachment.tags.includes(childData.id)),
                )
              "
              :empty-label="$t('No documents available.')"
              dense
              hide-remove
            />
          </FormQuestion>
        </div>
      </div>
    </template>

    <template v-if="subsidyProgram.verify_home_address">
      <v-divider class="my-4" />

      <FormQuestion
        :subtitle="
          subsidyProgram.verify_home_address_subtitle ||
          'Ex. A copy of current lease, proof of homeownership, or utility bill (with service or premise address listed) such as your bill for gas, electric, water, or cable.'
        "
        :title="
          subsidyProgram.verify_home_address_title ||
          'Upload a document to verify your current address'
        "
        dense
        hide-actions
        no-print
        paddingless
      >
        <AttachmentList
          :attachments="
            attachments.filter(
              (attachment) =>
                attachment.tag == 'proof-of-residency' ||
                attachment.tags.includes('proof-of-residency'),
            )
          "
          :empty-label="$t('No documents available.')"
          dense
          hide-remove
        />
      </FormQuestion>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import Child from '@/parent/components/children/Child.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    AttachmentList,
    Child,
    FormQuestion,
    LabeledSimpleSelect,
    LabeledTextfield,
    QuestionSet,
  },

  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
    childData: {
      type: Object,
      default: null,
    },
    childEligibilityQuestions: {
      type: Array,
      default: () => [],
    },
    childQuestions: {
      type: Array,
      default: () => [],
    },
    childSchema: {
      type: Object,
      default: null,
    },
    childVerificationQuestions: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Object,
      default: null,
    },
    groupEligibilityQuestions: {
      type: Array,
      default: () => [],
    },
    groupQuestions: {
      type: Array,
      default: () => [],
    },
    groupSchema: {
      type: Object,
      default: null,
    },
    groupVerificationQuestions: {
      type: Array,
      default: () => [],
    },
    subsidyProgram: {
      type: Object,
      default: null,
    },
  },

  methods: {
    shouldShowHomelessnessQuestion() {
      return (
        !_.isNil(this.group.experiencing_homelessness) &&
        this.subsidyProgram.enable_homeless_address_option
      );
    },
  },
};
</script>
