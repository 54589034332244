<template>
  <div
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
    :id="'tab_' + order"
    :class="containerClass"
    class="pa-4 d-flex align-center focus-visible"
    tabindex="0"
  >
    <template v-if="!hideIcon">
      <v-icon
        v-if="locked"
        :class="heavyActive"
        :disabled="locked"
        class="me-2"
        color="gray"
        size="22"
      >
        lock_outline
      </v-icon>
      <v-icon
        v-if="progress == 100"
        :class="heavyActive"
        class="me-2"
        color="green"
        size="22"
      >
        check_circle
      </v-icon>
      <v-progress-circular
        v-if="!locked && progress < 100"
        :model-value="progress"
        class="me-2"
        size="22"
        width="2"
      >
        <span
          v-text="order"
          class="fs-12"
        />
      </v-progress-circular>
    </template>
    <div>
      <div
        :class="underlinedHeavyActive"
        class="fs-16"
      >
        {{ $t(title) }}
      </div>
    </div>
    <v-spacer />
    <v-icon>chevron_right</v-icon>
  </div>
</template>

<script setup>
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  dividedBottom: {
    type: Boolean,
    default: false,
  },
  hideIcon: {
    type: Boolean,
    default: false,
  },
  locked: {
    type: Boolean,
    default: false,
  },
  order: {
    type: String,
    default: null,
  },
  progress: {
    type: Number,
    default: 0,
  },
  title: {
    type: String,
    default: null,
  },
});

defineEmits(['click']);

const heavyActive = computed(() => (props.active ? 'fw-600' : 'fw-500'));

const underlinedHeavyActive = computed(() => {
  const classes = [heavyActive.value];
  if (props.active) classes.push('underlined');
  return classes.join(' ');
});

const containerClass = computed(() => {
  const classes = [];
  if (props.dividedBottom) {
    classes.push('bb-1 bc-very-light-gray');
  }

  if (props.locked) {
    classes.push('cursor-default');
  } else {
    classes.push('pointer');
  }

  return classes.join(' ');
});
</script>
