<template>
  <div
    v-if="subsidyPrograms"
    class="h-100pc"
  >
    <v-row
      v-if="providers"
      class="h-100pc"
      dense
      no-gutters
    >
      <v-col class="h-100pc">
        <v-row
          v-if="subsidyPrograms.length > 1"
          class="bg-primary pt-3 mb-2"
        >
          <v-col class="d-flex justify-center py-6">
            <v-select
              v-model="selectedSubsidyProgram"
              :aria-label="$t('Select a subsidy program')"
              :item-title="(sp) => $t(sp.name)"
              :items="subsidyPrograms"
              class="mxw-400"
              density="comfortable"
              item-value="id"
              variant="solo"
              hide-details
              return-object
            />
          </v-col>
        </v-row>

        <v-container class="px-lg-8">
          <div
            v-if="$store.state.pages.Provider.features.enable_add_location && providers.length > 1"
            class="mb-4 mxw-300 ms-auto ta-right"
          >
            <v-btn
              @click="$refs.requestProviderLocationDialog.open()"
              color="primary"
              data-cy="add-location-top"
            >
              <v-icon start> add </v-icon>
              {{ $t('Add location') }}
            </v-btn>
          </div>

          <AccessRequestCard
            v-for="accessRequest in accessRequests"
            @destroyed="loadAccessRequests"
            :key="accessRequest.id"
            :request="accessRequest"
            class="mb-6"
          />

          <OwnershipRequestCard
            v-for="ownerRequest in ownershipRequests"
            @destroyed="loadOwnershipRequests"
            :key="ownerRequest.id"
            :request="ownerRequest"
            class="mb-6"
          />

          <v-row
            v-if="providers.length == 0 && ownershipRequests.length == 0"
            class="mb-4"
          >
            <v-col>
              <div
                id="no_provider_message"
                class="c-light-black ta-center bc-body"
                data-cy="no-provider-message"
              >
                {{ $t("Oh no! Your email isn't associated with an existing child care program.") }}
              </div>
            </v-col>
          </v-row>

          <ProviderSummaryCard
            v-for="provider in providers"
            :key="provider.id"
            :provider="provider"
            :seat-types="seatTypes"
            :subsidy-program="selectedSubsidyProgram"
          />

          <div
            v-if="$store.state.pages.Provider.features.enable_add_location"
            class="mxw-300 mx-auto"
            data-cy="add-location-bottom"
          >
            <v-btn
              @click="$refs.requestProviderLocationDialog.open()"
              color="primary"
              size="x-large"
              block
            >
              <v-icon start> add </v-icon>
              {{ $t('Add location') }}
            </v-btn>
          </div>

          <VerticalSpacer :min-height="60" />
        </v-container>
      </v-col>
    </v-row>

    <RequestProviderLocationDialog
      @access-request-added="accessRequests.push($event)"
      @ownership-request-added="ownershipRequests.push($event)"
      ref="requestProviderLocationDialog"
      :access-requests="accessRequests"
      :ownership-requests="ownershipRequests"
    />
  </div>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import AccessRequestCard from '@/manager/components/provider/AccessRequestCard.vue';
import OwnershipRequestCard from '@/manager/components/provider/OwnershipRequestCard.vue';
import ProviderSummaryCard from '@/manager/components/provider/ProviderSummaryCard.vue';
import RequestProviderLocationDialog from '@/manager/components/provider/RequestProviderLocationDialog.vue';
import { useStore } from 'vuex';

const accessRequests = ref([]);
const ownershipRequests = ref([]);
const providers = ref(null);
const seatTypes = ref([]);
const selectedSubsidyProgram = ref(null);
const store = useStore();
const subsidyPrograms = ref(null);

onMounted(load);

watch(selectedSubsidyProgram, async () => {
  store.commit('setFilters', ['subsidy', { subsidyProgramId: selectedSubsidyProgram.value?.id }]);
  await loadSeatTypes();
});

async function load() {
  const resp = await api.public_api.organization.subsidy_program.index();
  subsidyPrograms.value = resp.data;

  const storedSubsidyProgramFilter = store.state.filters.subsidy?.subsidyProgramId;
  if (storedSubsidyProgramFilter) {
    selectedSubsidyProgram.value = subsidyPrograms.value.find(
      (sp) => sp.id === storedSubsidyProgramFilter,
    );
  } else {
    [selectedSubsidyProgram.value] = subsidyPrograms.value;
  }

  await Promise.allSettled([
    loadAccessRequests(),
    loadOwnershipRequests(),
    loadProviders(),
    loadSeatTypes(),
  ]);
}

async function loadAccessRequests() {
  const response = await api.manager.access_request.index();
  accessRequests.value = response.data;
}

async function loadOwnershipRequests() {
  const response = await api.manager.ownership_request.index({});
  ownershipRequests.value = response.data;
}

async function loadProviders() {
  const response = await api.manager.provider.index();
  providers.value = response.data;
}

async function loadSeatTypes() {
  if (!selectedSubsidyProgram.value) return;

  seatTypes.value = [];
  const params = {
    providers_can_manage: true,
    providers_can_view: true,
  };
  const resp = await api.member.subsidy_program.seat_type.index(
    selectedSubsidyProgram.value?.id,
    params,
  );
  seatTypes.value = resp.data;
}
</script>
