<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card
          class="mb-4"
          border
          flat
          tile
        >
          <v-card-title>{{ $t('Business information') }}</v-card-title>

          <v-divider />

          <v-card-text>
            <v-row>
              <LabeledTextfield
                v-model="business.name"
                @change="updateBusiness"
                :description="
                  $t(
                    'Your business name will be seen when communicating with support staff. If you have multiple locations, we recommend using your registered business name or LLC.',
                  )
                "
                :hard-lock="readOnly"
                :message="$t('Business name')"
              />
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          class="mb-4"
          border
          flat
          tile
        >
          <v-card-title>{{ $t('Locations') }}</v-card-title>

          <v-divider />

          <v-card-text>
            <v-card
              v-for="provider in providers"
              :key="provider.id"
              border
              flat
              tile
            >
              <v-card-text>
                <v-row dense>
                  <v-col class="d-flex align-center">
                    <v-icon
                      class="me-2"
                      color="primary"
                    >
                      place
                    </v-icon>
                    <div
                      v-text="provider.name"
                      class="fs-16 fw-500"
                    />
                  </v-col>
                  <v-col class="d-flex justify-end">
                    <div
                      v-text="[provider.address1, provider.city].join(', ')"
                      class="fs-16 fw-500 c-light-black"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>

        <template v-if="profile && !readOnly">
          <v-card
            class="mb-4"
            border
            flat
            tile
          >
            <v-card-title class="d-flex align-center">
              <span>{{ $t('Staff') }}</span>
              <v-spacer />
              <v-btn
                v-if="$store.state.profile.business_role == 'Admin'"
                @click="newMember"
                color="primary"
                data-cy="invite_member"
              >
                {{ $t('Invite') }}
              </v-btn>
            </v-card-title>

            <v-divider />

            <v-card-text>
              <div class="mb-4">
                {{ $t('Invite other staff members to manage your account.') }}
              </div>

              <StaffCard
                v-for="thisMember in members"
                @change="loadMembers"
                :key="thisMember.id"
                :member="thisMember"
                :providers="providers"
                class="mb-3"
              />
            </v-card-text>
          </v-card>
        </template>

        <template
          v-if="
            $store.state.pages.Settings.features &&
            $store.state.pages.Settings.features.enable_bank_account &&
            business.enable_payments
          "
        >
          <v-card
            class="mb-4"
            border
            flat
            tile
          >
            <v-card-title>Bank account</v-card-title>

            <v-divider />

            <v-card-text>
              <div class="labeled-input mb-3">
                {{
                  $t(
                    'Your banking information is requested in order to deposit funds when you apply for a grant or receive child care payments. This level of permission only allows deposits. We will never withdraw anything from the account.',
                  )
                }}
              </div>

              <template v-if="business.bank_account_name">
                <div class="d-flex align-center my-3">
                  <v-row class="d-flex align-center">
                    <v-col>
                      <div class="fs-14 c-light-black">
                        {{ $t('Account name') }}
                      </div>
                      <div
                        v-text="business.bank_account_name || '-'"
                        class="fs-16"
                      />
                    </v-col>
                    <v-col>
                      <div class="fs-14 c-light-black">
                        {{ $t('Account type') }}
                      </div>
                      <div
                        v-text="business.bank_account_type || '-'"
                        class="fs-16"
                      />
                    </v-col>
                    <v-col>
                      <div class="fs-14 c-light-black">
                        {{ $t('Routing number') }}
                      </div>
                      <div
                        v-text="business.bank_routing_number || '-'"
                        class="fs-16"
                      />
                    </v-col>
                    <v-col>
                      <div class="fs-14 c-light-black">
                        {{ $t('Account number') }}
                      </div>
                      <div
                        v-text="business.bank_account_number_last_four.toString().padStart(10, 'X')"
                        class="fs-16"
                      />
                    </v-col>
                  </v-row>
                </div>
              </template>
            </v-card-text>
            <v-card-actions>
              <v-btn
                @click="editBankAccount"
                color="primary"
                size="x-large"
              >
                {{ $t('Update bank account') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <v-dialog
      v-model="memberDialogIsVisible"
      max-width="600px"
    >
      <v-card
        border
        flat
        tile
      >
        <v-card-title>{{ $t(member.id ? 'Update member' : 'Invite member') }}</v-card-title>
        <v-card-text class="pt-4 pb-8">
          <v-row>
            <LabeledTextfield
              v-model="member.first_name"
              data-cy="invite_first_name"
              message="First name"
            />
            <LabeledTextfield
              v-model="member.last_name"
              data-cy="invite_last_name"
              message="Last name"
            />
            <LabeledTextfield
              v-model="member.email"
              data-cy="invite_email"
              message="Email"
            />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="memberDialogIsVisible = false"
            color="primary"
            size="x-large"
            variant="text"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="createOrUpdateMember"
            :disabled="!member.first_name || !member.last_name || !member.email || processing"
            :ripple="false"
            color="primary"
            data-cy="invite_submit"
            size="x-large"
          >
            {{ $t('Submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="bankDialogIsVisible"
      max-width="600px"
    >
      <v-card
        border
        flat
        tile
      >
        <v-card-title>Update bank account</v-card-title>
        <v-divider />
        <v-card-text class="pt-4 pb-8">
          <v-row>
            <LabeledTextfield
              v-model="newVal.bank_routing_number"
              description="The 9-digit number on your check identifies your bank and tells us where to find your account."
              message="Routing number"
            />
            <LabeledTextfield
              v-model="newVal.bank_account_number"
              message="Account number"
            />
            <LabeledSelect
              v-model="newVal.bank_account_type"
              :items="['Checking', 'Savings']"
              :multiple="false"
              message="Bank account type"
            />
            <LabeledTextfield
              v-model="newVal.bank_account_name"
              description="Add a nickname so you can identify your account."
              message="Account nickname"
            />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="bankDialogIsVisible = false"
            color="primary"
            size="x-large"
            variant="text"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="updateBankAccount"
            :disabled="invalidAccount"
            :loading="processing"
            color="primary"
            size="x-large"
          >
            {{ $t('Submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <VerticalSpacer :min-height="60" />
  </v-container>
</template>
<script>
import API from '@/shared/mixins/api';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import StaffCard from '@/manager/components/StaffCard.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LabeledTextfield,
    StaffCard,
  },

  mixins: [API],

  data() {
    return {
      bankDialogIsVisible: false,
      business: {},
      loaded: false,
      newVal: this.emptyBankAccount(),
      member: {},
      memberDialogIsVisible: false,
      members: [],
      processing: false,
      profile: null,
      providers: [],
    };
  },

  computed: {
    invalidAccount() {
      return !(
        this.newVal.bank_routing_number &&
        this.newVal.bank_routing_number.length === 9 &&
        this.newVal.bank_account_name &&
        this.newVal.bank_account_type &&
        this.newVal.bank_account_number &&
        this.newVal.bank_account_number.length > 6
      );
    },

    readOnly() {
      return this.$store.state.profile.business_role !== 'Admin';
    },
  },

  created() {
    this.load();
  },

  methods: {
    async createOrUpdateMember() {
      this.processing = true;

      const resp = await this.api.manager.member.createOrUpdate(this.member);
      if (!resp.data) {
        this.processing = false;
        return;
      }

      this.$eventBus.$emit('chime', this.member.id ? 'Saved' : 'Invitation sent');
      this.loadMembers();
      this.memberDialogIsVisible = false;
      this.processing = false;
    },

    editBankAccount() {
      this.newVal = {};
      this.bankDialogIsVisible = true;
    },

    editMember(member) {
      this.member = member;
      this.memberDialogIsVisible = true;
    },

    emptyBankAccount() {
      return {
        bank_account_name: null,
        bank_account_number: null,
        bank_account_type: null,
        bank_routing_number: null,
      };
    },

    load() {
      this.api.manager.business.get((resp) => {
        this.business = resp.data;
      });
      this.api.manager.profile.get((resp) => {
        this.profile = resp.data;
      });

      this.loadProviders();
      this.loadMembers();
    },

    async loadMembers() {
      const resp = await this.api.manager.member.index({});
      this.members = resp.data;
    },

    async loadProviders() {
      const { data } = await this.api.manager.provider.index();
      this.providers = data;
    },

    newMember() {
      this.member = {};
      this.memberDialogIsVisible = true;
    },

    updateBankAccount() {
      this.processing = true;
      this.api.manager.business.update(this.newVal, (resp) => {
        this.processing = false;
        this.bankDialogIsVisible = false;
        this.business = resp.data;
      });
    },

    updateBusiness() {
      this.api.manager.business.update({ name: this.business.name }, (resp) => {
        this.business = resp.data;
        this.$eventBus.$emit('chime', 'Saved');
      });
    },
  },
};
</script>
